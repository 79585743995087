require('./bootstrap');

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from "./store";
import Notifications from '@kyvg/vue3-notification';
import VTooltip from 'v-tooltip';
import "v-tooltip/dist/v-tooltip.css";
import Vue3Autocounter from 'vue3-autocounter';
import methods from './methods';

router.beforeEach((to, from, next) => {
    if(to.name == "Stream"){ // Page de stream
        next();
    }else if(to.name == "Account" && from.name == "Account Page"){ // Page de vue de l'utilisateur au compte connecté
        testConnexion(store).then(() => {
            next({ name: 'Account' });
        }).catch(() => {
            next();
        });
    }else if(to.name == "Login"){ // Page de connexion
        if(store.getters.isConnected){ // Si déjà connecté
            next({ name: 'Account' });
        }else{ // Si pas connecté
            testConnexion(store).then(() => {
                next({ name: 'Account' });
            }).catch(() => {
                next();
            });
        }
    }else if(to.matched.some(record => record.meta.requiresAuth)) { // Besoin d'une connexion pour rejoindre la page
        if (store.getters.isConnected) {
            next(); // S'il est connecté
        } else {
            testConnexion(store).then(() => {
                next();
            }).catch(() => {
                next({ name: 'Login' });
            });
        }
    }else{ // Page non pris en compte, mais récupération du compte connecté
        testConnexion(store).then(() => {
            next(); // N'a pas besoin de la connexion
        }).catch(() => {
            next(); // N'a pas besoin de la connexion
        });
    }
});

createApp(App)
    .use(Notifications)
    .use(router)
    .use(store)
    .use(VTooltip)
    .component('vue3-autocounter', Vue3Autocounter)
    .mount("#app");

function testConnexion(store){
    return new Promise((resolve, reject) => {
        axios.post('/api/user/account', {}, {headers: {'Authorization': `Bearer ${localStorage.getItem("token")}`}}).then((object) => {
            methods.setUserAccount(store.state, object.data);
            resolve();
        }).catch((err) => {
            reject();
        });
    });
}