<template>
   <div class="wrapper register-page">
     <div class="page-header espace-min-height">
       <div class="content">
         <div class="container">
 
           <div class="row justify-content-center">
             
             <div class="col-6">
               <div class="card card-register">
                 <ul class="nav nav-tabs nav-tabs-primary ul-center pt-4" role="tablist">
                   <li class="nav-item">
                     <a class="nav-link active" data-toggle="tab" href="#to-connexion" role="tablist">
                       Connexion
                     </a>
                   </li>
                   <li class="nav-item">
                     <a class="nav-link" data-toggle="tab" href="#to-inscription" role="tablist">
                       Inscription
                     </a>
                   </li>
                 </ul>
 
                 <div class="tab-content tab-space">
                   <div class="tab-pane active" id="to-connexion">
                     <div class="card-body">
                       <form class="form">
                         <div class="input-group">
                           <div class="input-group-prepend">
                             <label class="input-group-text" for="email-connexion">
                               <i class="fas fa-user"></i>
                             </label>
                           </div>
                           <input type="email" class="form-control" id="email-connexion" v-model="formLogin.email" placeholder="Email" v-on:keyup.enter="login()">
                         </div>
                         <div class="input-group">
                           <div class="input-group-prepend">
                             <label class="input-group-text" for="password-connexion">
                               <i class="fas fa-key"></i>
                             </label>
                           </div>
                           <input type="password" class="form-control" id="password-connexion" v-model="formLogin.password" placeholder="Mot de passe" v-on:keyup.enter="login()">
                         </div>
                       </form>
                       <a href="#" @click.stop.prevent="forgotPassword()">Mot de passe perdu ?</a>
                       <div class="alert alert-danger text-center mt-1" v-if="this.$store.getters.getErrors.login.length != 0">{{ this.$store.getters.getErrors.login }}</div>
                     </div>
                     <div class="card-footer text-center p-0 m-0">
                       <a href="#" class="btn btn-primary btn-round btn-lg" @click.stop.prevent="login()">Se connecter</a>
                     </div>
                   </div>

                   <div class="tab-pane" id="to-inscription">
                      <div class="card-body">
                        <form class="form">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <label class="input-group-text" for="pseudo-inscription">
                                <i class="fas fa-user"></i>
                              </label>
                            </div>
                            <input type="text" class="form-control" id="pseudo-inscription" v-model="formRegister.pseudo" placeholder="Pseudo" v-on:keyup.enter="register()" required>
                          </div>
                          <ul v-if="this.$store.getters.getErrors.register.pseudo.length != 0">
                            <li class="message-error" v-for="(errorText, index) in this.$store.getters.getErrors.register.pseudo" :key="index">
                              {{ errorText }}
                            </li>
                          </ul>
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <label class="input-group-text" for="email-inscription">
                                <i class="fas fa-envelope"></i>
                              </label>
                            </div>
                            <input type="text" id="email-inscription" class="form-control" v-model="formRegister.email" placeholder="Email" v-on:keyup.enter="register()" required>
                          </div>
                          <ul v-if="this.$store.getters.getErrors.register.email.length != 0">
                            <li class="message-error" v-for="(errorText, index) in this.$store.getters.getErrors.register.email" :key="index">
                              {{ errorText }}
                            </li>
                          </ul>
                          <div class="input-group input-password-width">
                            <div class="input-group-prepend">
                              <label class="input-group-text" for="password-inscription">
                                <i class="fas fa-key"></i>
                              </label>
                            </div>
                            <input type="password" class="form-control" id="password-inscription" v-model="formRegister.password" placeholder="Mot de passe" v-on:keyup.enter="register()" required>
                            <div class="btn-show-password" v-tooltip.right="'Afficher/cacher le mot de passe'">
                                <a href="#" class="p-2 show-password" @click.stop.prevent="showPasswordRegister()">
                                  <i class="fas fa-eye"></i>
                                </a>
                            </div>
                          </div>
                          <ul v-if="this.$store.getters.getErrors.register.password.length != 0">
                            <li class="message-error" v-for="(errorText, index) in this.$store.getters.getErrors.register.password" :key="index">
                              {{ errorText }}
                            </li>
                          </ul>
                          <div class="form-check text-left">
                            <label class="form-check-label">
                              <input class="form-check-input" type="checkbox" v-model="formRegister.cgu" required>
                              <span class="form-check-sign"></span>
                              J'accepte les <router-link to="/cgu">CGU</router-link>.
                            </label>
                          </div>
                          <ul v-if="this.$store.getters.getErrors.register.cgu.length != 0">
                            <li class="message-error" v-for="(errorText, index) in this.$store.getters.getErrors.register.cgu" :key="index">
                              {{ errorText }}
                            </li>
                          </ul>
                        </form>
                      </div>
                      <div class="card-footer text-center p-0 m-0">
                        <a href="#" class="btn btn-primary btn-round btn-lg" @click.stop.prevent="register()">S'inscrire</a>
                      </div>
                  </div>
                 </div>
                 
               </div>
             </div>
 
           </div>
         </div>
       </div>

        <div id="square1" class="square square-1"></div>
        <div id="square2" class="square square-2"></div>
        <div id="square3" class="square square-3"></div>
        <div id="square4" class="square square-4"></div>
        <div id="square5" class="square square-5"></div>
        <div id="square6" class="square square-6"></div>
        <div id="square7" class="square square-7"></div>
        <div id="square8" class="square square-8"></div>
     </div>
     
    <!-- MODAL LOST PASSWORD -->
    <div class="modal fade modal-black" id="modal-lost-password" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header justify-content-center">
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
              <i class="fas fa-times text-white"></i>
            </button>
            <div class="text-muted text-center ml-auto mr-auto">
              <h3 class="mb-0">Mot de passe perdu ?</h3>
            </div>
          </div>
          <div class="modal-body">
            <form class="form">
              <div class="input-group">
                <div class="input-group-prepend">
                  <label class="input-group-text" for="email-lost-password">
                    <i class="fas fa-envelope"></i>
                  </label>
                </div>
                <input type="text" id="email-lost-password" class="form-control" v-model="formforgotPassword.email" placeholder="Email" v-on:keyup.enter="getMailToResetPassword()" required>
              </div>
              <ul v-if="this.$store.getters.getErrors.forgotPassword.email.length != 0">
                <li class="message-error" v-for="(errorText, index) in this.$store.getters.getErrors.forgotPassword.email" :key="index">
                  {{ errorText }}
                </li>
              </ul>
            </form>
          </div>
          <div class="text-center p-0 m-0 pb-4">
            <a href="#" class="btn btn-primary btn-round btn-lg" @click.stop.prevent="getMailToResetPassword()">Envoyer</a>
          </div>
        </div>
      </div>
    </div>

   </div>
</template>

<script>
import { notify } from "@kyvg/vue3-notification";

export default{
  mounted(){
    $(document).mousemove(function(e) {
      let posX = e.clientX - window.innerWidth / 2;
      let posY = e.clientY - window.innerWidth / 6;

      if(document.getElementById("square1") != null){
        document.getElementById("square1").style.transform = "perspective(500px) rotateY(" + posX * 0.05 + "deg) rotateX(" + posY * (-0.05) + "deg)";
      }
      if(document.getElementById("square2") != null){
        document.getElementById("square2").style.transform = "perspective(500px) rotateY(" + posX * 0.03 + "deg) rotateX(" + posY * (-0.03) + "deg)";
      }
      if(document.getElementById("square3") != null){
        document.getElementById("square3").style.transform = "perspective(500px) rotateY(" + posX * 0.04 + "deg) rotateX(" + posY * (-0.04) + "deg)";
      }
      if(document.getElementById("square4") != null){
        document.getElementById("square4").style.transform = "perspective(500px) rotateY(" + posX * 0.05 + "deg) rotateX(" + posY * (-0.05) + "deg)";
      }
      if(document.getElementById("square5") != null){
        document.getElementById("square5").style.transform = "perspective(500px) rotateY(" + posX * 0.08 + "deg) rotateX(" + posY * (-0.08) + "deg)";
      }
      if(document.getElementById("square6") != null){
        document.getElementById("square6").style.transform = "perspective(500px) rotateY(" + posX * 0.05 + "deg) rotateX(" + posY * (-0.05) + "deg)";
      }
      if(document.getElementById("square7") != null){
        document.getElementById("square7").style.transform = "perspective(500px) rotateY(" + posX * 0.03 + "deg) rotateX(" + posY * (-0.03) + "deg)";
      }
      if(document.getElementById("square8") != null){
        document.getElementById("square8").style.transform = "perspective(500px) rotateY(" + posX * 0.09 + "deg) rotateX(" + posY * (-0.09) + "deg)";
      }
    });
  },
  setup(){
    return {
      formLogin: {
        email: '',
        password: ''
      },
      formRegister: {
        pseudo: '',
        email: '',
        password: '',
        cgu: false
      },
      formforgotPassword: {
        email: ''
      }
    };
  },
  methods: {
    login(){
      this.$store.dispatch('login', this.formLogin).then(() => {
        notify({
          text: "🔓 Tu es bien connecté !",
          type: "success"
        });

        this.$router.push({ name: 'Account'});
      });
    },
    register(){
      this.$store.dispatch('register', this.formRegister).then(() => {
        notify({
          text: "🔐 Tu viens de créer votre compte !",
          type: "success"
        });

        this.$router.push({ name: 'Account'});
      });
    },
    showPasswordRegister(){
      let password = $("#password-inscription");

      if(password.attr('type') === "password"){
        password.attr('type', 'text');
      }else{
        password.attr('type', 'password');
      }
    },
    forgotPassword(){
      $("#modal-lost-password").modal('show');
    },
    getMailToResetPassword(){
      this.$store.dispatch('getMailToResetPassword', this.formforgotPassword).then(() => {
        $("#modal-lost-password").modal('hide');

        this.formforgotPassword.email = '';
        $("#email-lost-password").val(this.formforgotPassword.email);

        notify({
          text: "🔐 Un email a été envoyé à été pour réinitialiser ton mot de passe !",
          type: "success"
        });
      });
    },
  },
}
</script>
<style scoped>
.btn-show-password{
  position:absolute; 
  right: -36px; 
  top: 8px;
}
.input-password-width{
  width: 92%;
}
</style>