import Vuex from "vuex";
import methods from '../methods';

export default new Vuex.Store({
    state: {
        config: {
            nomSite: "Crypto-Don",
            lienSite: "https://crypto-don.vizion-web.be/",
            mailSupport: "support@crypto-don.vizion-web.be",
            dateMaj: "30/08/2022",
        },
        errors: {
            login: [],
            register: {
                pseudo: [],
                email: [],
                password: [],
                cgu: [],
            },
            updateAccount: {
                pseudo: [],
                email: [],
                pseudo_twitter: [],
                pseudo_twitch: [],
                link_facebook: [],
                link_youtube: [],
                link_tiktok: [],
                profil_text: [],
            },
            changePassword: {
                password: [],
                password_confirm: []
            },
            contact: {
                email: [],
                category: [],
                sujet: [],
                description: [],
                captchaResponse: [],
            },
            forgotPassword: {
                email: [],
            },
            resetPassword: {
                email: [],
                password: [],
            }
        },
        account: {
            user: null,
            cryptos: {
                // TODO: Cryptos ADD
                algo: {
                    name: "Algorand",
                    address: null
                },
                xlm: {
                    name: "Stellars Lumens",
                    memo: null,
                    address: null
                },
                flux: {
                    name: "Flux",
                    address: null
                },
                /*
                atom: {
                    name: "Atom",
                    address: null
                },
                */
            }
        },
        statsGlobal: {
            addresses: 0,
            donations: 0,
            members: 0,
            sharedNetworks: 0,
        },
    },
    mutations: {
        /** Account */
        login(state, object){
            state.errors.login = [];

            methods.setUserAccount(state, object);
            setToken(object.token);
        },
        logout(state){
            state.account.user = null;
            
            removeToken()
        },
        saveAccount(state, user){
            state.account.user = user;

            state.errors.updateAccount= {
                pseudo: [],
                email: [],
                pseudo_twitter: [],
                pseudo_twitch: [],
                link_facebook: [],
                link_youtube: [],
                link_tiktok: [],
                profil_text: [],
            };
        },
        register(state, object){
            state.errors.register = {
                pseudo: [],
                email: [],
                password: [],
                cgu: [],
            };
            
            methods.setUserAccount(state, object);
            setToken(object.token);
        },
        changePassword(state){
            state.errors.changePassword = {
                password: [],
                password_confirm: []
            };
        },
        getMailToResetPassword(state){
            state.errors.forgotPassword = {
                email: [],
            };
        },
        resetPassword(state){
            state.errors.resetPassword = {
                email: [],
                code: [],
                password: [],
            };
        },
        refreshToken(state, code_stream){
            state.account.user.code_stream = code_stream;
        },
        getViewAccount(state, object){
            // TODO: Cryptos ADD
            state.account.user = object.user;
            state.account.cryptos.algo.address = object.cryptos.algo;
            state.account.cryptos.xlm.address = object.cryptos.xlm;
            state.account.cryptos.xlm.memo = object.cryptos.xlm_memo;
            state.account.cryptos.flux.address = object.cryptos.flux;
        },
        updateLogo(state, logo){
            state.account.user.logo = logo;
        },
        
        /** Address */
        updateAddress(state, crypto){
            state.account.cryptos[crypto.name].address = crypto.address;
            if(methods.trueIfMemoExist(crypto.name)){
                state.account.cryptos[crypto.name].memo = crypto.memo;
            }
        },
        deleteAddress(state, cryptoName){
            state.account.cryptos[cryptoName].address = null;
        },

        /** Config */
        getStatsGlobal(state, stats){
            state.statsGlobal = stats;
        },

        /* Contact */
        sendRequest(state){
            state.errors.contact = {
                email: [],
                category: [],
                sujet: [],
                description: [],
                captchaResponse: [],
            };
        },
    },
    actions: {
        /** Config */
        getStatsGlobal({commit}){
            axios.post('/api/statsGlobal').then((get) => {
                commit("getStatsGlobal", get.data);
            });
        },
        addTransaction({commit}, object){
            axios.post('/api/sendTransaction', object);
        },

        /** Account */
        login({commit}, form){
            return new Promise((resolve, reject) => {
                axios.get('/sanctum/csrf-cookie').then(() => {
                    axios.post('/api/user/login', form).then(get => {
                        commit('login', get.data);
                        resolve();
                    }).catch((err) => {
                        this.state.errors.login = "Pseudo/email ou mot de passe incorrect !";
                    });
                }).catch((err) => {
                    console.log("Erreur Sanctum !");
                });
            });
        },
        register({commit}, form){
            return new Promise((resolve, reject) => {
                axios.post('/api/user/register', form).then(get => {
                    commit('register', get.data);
                    resolve();
                }).catch((err) => {
                    this.state.errors.register.pseudo = err.response.data.errors.pseudo != null ? err.response.data.errors.pseudo : [];
                    this.state.errors.register.email = err.response.data.errors.email != null ? err.response.data.errors.email : [];
                    this.state.errors.register.password = err.response.data.errors.password != null ? err.response.data.errors.password : [];
                    this.state.errors.register.cgu = err.response.data.errors.cgu != null ? err.response.data.errors.cgu : [];
                });
            });
        },
        logout({commit}){
            return new Promise((resolve, reject) => {
                axios.post('/api/user/logout', {}, {headers: {'Authorization': `Bearer ${localStorage.getItem("token")}`}}).then(() => {
                    commit('logout');
                    resolve();
                });
            });
        },
        getMailToResetPassword({commit}, form){
            return new Promise((resolve, reject) => {
                axios.post('/api/password/email', form).then(() => {
                    commit('getMailToResetPassword');
                    resolve();
                }).catch((err) => {
                    this.state.errors.forgotPassword.email = err.response.data.errors.email != null ? err.response.data.errors.email : [];
                });
            });
        },
        resetPassword({commit}, form){
            return new Promise((resolve, reject) => {
                axios.post('/api/password/reset', form).then(() => {
                    commit('resetPassword');
                    resolve();
                }).catch((err) => {
                    if(err.response.data.errors != null){
                        this.state.errors.resetPassword.email = err.response.data.errors.email != null ? err.response.data.errors.email : [];
                        this.state.errors.resetPassword.password = err.response.data.errors.password != null ? err.response.data.errors.password : [];
                        
                        if(err.response.data.errors.code != null){
                            reject(err.response.data.errors.code[0]);
                        }
                    }else{
                        reject(err.response.data.code);
                    }
                });
            });
        },
        saveAccount({commit}, formAccount){
            return new Promise((resolve, reject) => {
                axios.post('/api/user/saveAccount', formAccount, {headers: {'Authorization': `Bearer ${localStorage.getItem("token")}`}}).then((get) => {
                    commit('saveAccount', get.data);
                    resolve();
                }).catch((err) => {
                    this.state.errors.updateAccount.pseudo = err.response.data.errors.pseudo != null ? err.response.data.errors.pseudo : [];
                    this.state.errors.updateAccount.email = err.response.data.errors.email != null ? err.response.data.errors.email : [];
                    this.state.errors.updateAccount.password = err.response.data.errors.password != null ? err.response.data.errors.password : [];
                    this.state.errors.updateAccount.pseudo_twitter = err.response.data.errors.pseudo_twitter != null ? err.response.data.errors.pseudo_twitter : [];
                    this.state.errors.updateAccount.pseudo_twitch = err.response.data.errors.pseudo_twitch != null ? err.response.data.errors.pseudo_twitch : [];
                    this.state.errors.updateAccount.link_facebook = err.response.data.errors.link_facebook != null ? err.response.data.errors.link_facebook : [];
                    this.state.errors.updateAccount.link_youtube = err.response.data.errors.link_youtube != null ? err.response.data.errors.link_youtube : [];
                    this.state.errors.updateAccount.link_tiktok = err.response.data.errors.link_tiktok != null ? err.response.data.errors.link_tiktok : [];
                    this.state.errors.updateAccount.profil_text = err.response.data.errors.profil_text != null ? err.response.data.errors.profil_text : [];
                });
            });
        },
        changePassword({commit}, formPassword){
            return new Promise((resolve, reject) => {
                axios.post('/api/user/changePassword', formPassword, {headers: {'Authorization': `Bearer ${localStorage.getItem("token")}`}}).then((get) => {
                    commit('changePassword');
                    resolve();
                }).catch((err) => {
                    this.state.errors.changePassword.password = err.response.data.errors.password != null ? err.response.data.errors.password : [];
                    this.state.errors.changePassword.password_confirm = err.response.data.errors.password_confirm != null ? err.response.data.errors.password_confirm : [];
                });
            });
        },
        refreshToken({commit}){
            return new Promise((resolve, reject) => {
                axios.post('/api/user/refreshToken', {}, {headers: {'Authorization': `Bearer ${localStorage.getItem("token")}`}}).then((get) => {
                    commit('refreshToken', get.data);
                    resolve();
                });
            });
        },
        getViewAccount({commit}, id){
            return new Promise((resolve, reject) => {
                axios.post('/api/user/accountView', id).then((get) => {
                    commit('getViewAccount', get.data);
                    resolve();
                }).catch((err) => {
                    reject(err.response.data.errors.id);
                });
            });
        },
        updateLogo({commit}, logo){
            let backupLogo = this.state.account.user.logo;
            this.state.account.user.logo = "spinner.gif";

            return new Promise((resolve, reject) => {
                axios.post('/api/user/updateLogo', logo, {headers:{'Authorization': `Bearer ${localStorage.getItem("token")}`, 'Content-Type': "multipart/form-data"}}).then((get) => {
                    commit('updateLogo', get.data);
                    resolve();
                }).catch((err) => {
                    commit('updateLogo', backupLogo);
                    reject(err.response.data.errors.logo);
                });
            });
        },
        getAddressForSteam({commit}, code){
            return new Promise((resolve, reject) => {
                axios.post('/api/stream', code).then((get) => {
                    resolve(get.data);
                }).catch((err) => {
                    reject();
                });
            });
        },

        /** Address */
        addAddress({commit}, address){
            return new Promise((resolve, reject) => {
                axios.post('/api/user/saveAddress', address, {headers: {'Authorization': `Bearer ${localStorage.getItem("token")}`}}).then((get) => {
                    commit('updateAddress', get.data);
                    resolve();
                });
            });
        },
        updateAddress({commit}, address){
            return new Promise((resolve, reject) => {
                axios.post('/api/user/saveAddress', address, {headers: {'Authorization': `Bearer ${localStorage.getItem("token")}`}}).then((get) => {
                    commit('updateAddress', get.data);
                    resolve();
                });
            });
        },
        deleteAddress({commit}, crypto){
            return new Promise((resolve, reject) => {
                axios.post('/api/user/deleteAddress', crypto, {headers: {'Authorization': `Bearer ${localStorage.getItem("token")}`}}).then((get) => {
                    commit('deleteAddress', crypto.name);
                    resolve();
                });
            });
        },
        
        /* Contact */
        sendRequest({commit}, formContact){
            return new Promise((resolve, reject) => {
                axios.post('/api/contact', formContact).then(() => {
                    commit('sendRequest');
                    resolve();
                }).catch((err) => {
                    if(err.response.data.errors != null){
                        this.state.errors.contact.email = err.response.data.errors.email != null ? err.response.data.errors.email : [];
                        this.state.errors.contact.category = err.response.data.errors.category != null ? err.response.data.errors.category : [];
                        this.state.errors.contact.sujet = err.response.data.errors.sujet != null ? err.response.data.errors.sujet : [];
                        this.state.errors.contact.description = err.response.data.errors.description != null ? err.response.data.errors.description : [];
                        this.state.errors.contact.captchaResponse = err.response.data.errors.captchaResponse != null ? err.response.data.errors.captchaResponse : [];

                        reject(null);
                    }else{
                        reject(err.response.data.error);
                    }
                });
            });
        },
    },
    getters:{
        isConnected: state => { return state.account.user != null },
        getErrors: state => { return state.errors },
        getUser: state => { return state.account.user },
        getConfig: state => { return state.config },
        getUserCrypto: state => { return state.account.cryptos },
        getStatsGlobal: state => { return state.statsGlobal },
        isUserHaveNotAllCrypto: state => { 
            for (const [crypto, object] of Object.entries(state.account.cryptos)) {
                if(object.address == null){
                    return true;
                }
            }

            return false;
        }
    },
    modules: { },
});

function setToken(token){
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    localStorage.setItem("token", token);
}

function removeToken(){
    axios.defaults.headers.common["Authorization"] = ``;
    localStorage.removeItem("token");
}
