<template>
  <div class="wrapper faq-page">
    <div class="page-header espace-min-height">
      <div class="content">
        <img src="/img/path1.png" class="path">
        <img src="/img/path2.png" class="path2">

        <img src="/img/contact.svg" width="420" />

        <div class="container">
          <div class="card">
            <div class="card-header">
              <h2>Me contactez !</h2>
            </div>
            <div class="card-body">
              
              <form class="form">
                <input type="text" class="d-none" v-model="formContact.champTextCTA">
                <div class="form-group">
                  <div class="form-input text-left">
                    <label for="input-email">Ton email (afin de pouvoir te répondre)</label>
                    <input type="text" class="form-control" placeholder="Ton email" id="input-email" v-model="formContact.email">
                  </div>
                  <ul v-if="this.$store.getters.getErrors.contact.email.length != 0">
                    <li class="message-error" v-for="(errorText, index) in this.$store.getters.getErrors.contact.email" :key="index">
                      {{ errorText }}
                    </li>
                  </ul>
                </div>
                
                <div class="form-group">
                  <div class="form-input text-left">
                    <label for="input-category">La catégorie</label>
                    <select class="custom-select" id="input-category" v-model="formContact.category">
                      <option v-for="key in getCategoryMethod()" :key="key" :value="key">{{ key }}</option>
                    </select>
                  </div>
                  <ul v-if="this.$store.getters.getErrors.contact.category.length != 0">
                    <li class="message-error" v-for="(errorText, index) in this.$store.getters.getErrors.contact.category" :key="index">
                      {{ errorText }}
                    </li>
                  </ul>
                </div>

                <div class="form-group pt-2">
                  <div class="form-input text-left">
                    <label for="input-subject">Le sujet de ta demande</label>
                    <input type="text" class="form-control" placeholder="Ton sujet" v-model="formContact.sujet" id="input-subject">
                  </div>
                  <ul v-if="this.$store.getters.getErrors.contact.sujet.length != 0">
                    <li class="message-error" v-for="(errorText, index) in this.$store.getters.getErrors.contact.sujet" :key="index">
                      {{ errorText }}
                    </li>
                  </ul>
                </div>

                <div class="form-group">
                  <div class="form-input text-left">
                    <label for="input-description">Description</label>
                    <textarea class="form-control contact-description-height" placeholder="Ta description" id="input-description" v-model="formContact.description"></textarea>
                  </div>
                  <ul v-if="this.$store.getters.getErrors.contact.description.length != 0">
                    <li class="message-error" v-for="(errorText, index) in this.$store.getters.getErrors.contact.description" :key="index">
                      {{ errorText }}
                    </li>
                  </ul>
                </div>

                <div class="divCaptcha">
                  <VueRecaptcha
                    :sitekey="siteKey"
                    :load-recaptcha-script="true"
                    @verify="handleSuccess"
                    @error="handleError"
                  ></VueRecaptcha>
                </div>
                <ul v-if="this.$store.getters.getErrors.contact.captchaResponse.length != 0">
                  <li class="message-error" v-for="(errorText, index) in this.$store.getters.getErrors.contact.captchaResponse" :key="index">
                    {{ errorText }}
                  </li>
                </ul>

                <div class="container text-center pt-2">
                  <button type="submit" id="submit-contact" class="btn btn-outline-success" @click.stop.prevent="submit()">Envoyer !</button>
                </div>
              </form>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { notify } from "@kyvg/vue3-notification";
import { computed } from 'vue';
import { VueRecaptcha } from 'vue-recaptcha';

export default{
  components: {
    VueRecaptcha
  },
  setup(){
    const formContact = {
      email: "",
      category: "",
      sujet: "",
      description: "",
      champTextCTA: "",
      captchaResponse: "",
    };

    const siteKey = computed(() => {
      return '6LcITLwhAAAAAEi6teAolNk2R6Z9A5bm9lDBgId1';
    });

    const handleError = () => {
      // Empty
    };

    const handleSuccess = (response) => {
      formContact.captchaResponse = response;
    };

    return {
      handleSuccess,
      handleError,
      siteKey,
      formContact,
    }
  },
  methods: {
    submit(){
      $("#submit-contact").addClass("disabled");

      this.$store.dispatch('sendRequest', this.formContact).then(() => {
        notify({
          text: "📮 Ta demande a été envoyée !",
          type: "success"
				});

        setTimeout(() => {
          $("#submit-contact").removeClass("disabled");
        }, 10000);

        this.formContact = {
          email: "",
          category: "",
          sujet: "",
          description: "",
          champTextCTA: "",
          captchaResponse: "",
        }

        $("#input-email").val("");
        $("#input-category").val("");
        $("#input-subject").val("");
        $("#input-description").val("");
      }).catch((error) => {
        $("#submit-contact").removeClass("disabled");

        if(error != null){
          notify({
            text: error,
            type: "error"
          });
        }
      });
    },
    getCategoryMethod(){
      return [
        "Aide",
        "Question",
        "Partenariat",
        "Problème",
        "Autre",
      ];
    },
  },
}
</script>