<template>
	<nav class="navbar navbar-expand-lg fixed-top" color-on-scroll="100">
		<div class="container">
			<div class="navbar-translate">
				<router-link to="/" class="navbar-brand">
					<span>C</span>rypto-<span>D</span>on
				</router-link>
				<button type="button" class="navbar-toggler navbar-toggler" data-toggle="collapse" data-target="#navigation" aria-controls="navigation-index" aria-expanded="false" aria-label="Afficher la navigation">
					<span class="navbar-toggler-bar bar1"></span>
					<span class="navbar-toggler-bar bar2"></span>
					<span class="navbar-toggler-bar bar3"></span>
				</button>
			</div>
			<div class="collapse navbar-collapse justify-content-end" id="navigation">
				<div class="navbar-collapse-header">
					<div class="row">
						<div class="col-6 collapse-brand">
							<router-link to="/">
								<span>C</span>rypto-<span>D</span>on
							</router-link>
						</div>
						<div class="col-6 collapse-close text-right">
							<button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#navigation" aria-controls="navigation-index" aria-expanded="false" aria-label="Afficher la navigation">
								<i class="fas fa-times"></i>
							</button>
						</div>
					</div>
				</div>

				<ul class="navbar-nav">
					<li class="nav-item p-0">
						<router-link class="nav-link" to="/account" v-tooltip="'Votre compte'">
							<i class="fas fa-user"></i>
							<p class="d-lg-none d-xl-none">Ton compte</p>
						</router-link>
					</li>
					<li class="nav-item p-0">
						<router-link v-if="!this.$store.getters.isConnected" to="/login" class="nav-link" v-tooltip="'Se connecter'">
							<i class="fas fa-power-off"></i>
							<p class="d-lg-none d-xl-none">Se connecter</p>
						</router-link>
						<a v-else @click.stop.prevent="logout()" href="#" class="nav-link" v-tooltip="'Se déconnecter'">
							<i class="fas fa-power-off"></i>
							<p class="d-lg-none d-xl-none">Se déconnecter</p>
						</a>
					</li>
				</ul>
			</div>
		</div>
	</nav>

	<div id="app">
		<router-view></router-view>
  		<notifications position="top right" class="custom-notification" :speed="2000" />
	</div>
	
	<footer class="footer">
		<div class="container">
			<div class="row">
				<div class="col-md-3">
					<h1 class="title"><span>C</span>rypto-<span>D</span>on</h1>
					<p>Copyright © <a href="https://vizion-web.be/" rel="noopener" target="_blank">Vizon-Web</a> {{ getYear() }}</p>
					<button class="btn btn-primary btn-round" type="button" @click.stop.prevent="donationUrl()">
						<i class="fas fa-donate"></i> Me faire un don !
					</button>
				</div>
				<div class="offset-md-3 col-md-3">
					<ul class="nav">
						<li class="nav-item">
							<router-link to="/" class="nav-link">
								Accueil
							</router-link>
						</li>
						<li class="nav-item">
							<router-link to="/login" v-if="isNotConnected()" class="nav-link">
								Connexion/Inscription
							</router-link>
						</li>
						<li class="nav-item">
							<router-link to="/account" class="nav-link">
								Compte
							</router-link>
						</li>
						<li class="nav-item">
							<router-link to="/faq" class="nav-link">
								FAQ
							</router-link>
						</li>
						<li class="nav-item">
							<router-link to="/contact" class="nav-link">
								Contact
							</router-link>
						</li>
						<li class="nav-item">
							<router-link to="/cgu" class="nav-link">
								CGU
							</router-link>
						</li>
					</ul>
				</div>
				<div class="col-md-3">
					<h3 class="title">Me suivre</h3>
					<div class="btn-wrapper profile">
						<a target="_blank" href="https://discord.gg/UWt26V7mQJ" class="btn btn-icon btn-neutral btn-round btn-simple mr-2" v-tooltip.top="'Mon discord'">
							<i class="fab fa-discord"></i>
						</a>
						<a target="_blank" href="https://vizion-web.be/" class="btn btn-icon btn-neutral btn-round btn-simple" v-tooltip.top="'Mon site'">
							<i class="fas fa-globe"></i>
						</a>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
import { notify } from "@kyvg/vue3-notification";

export default{
	methods: {
		getYear(){
			return new Date().getFullYear();
		},
		logout(){
        	this.$store.dispatch('logout').then(() => {
				notify({
					text: "🔒 Tu es correctement déconnecté !",
					type: "success"
				});

				this.$router.push({name: 'Login'});
			});
		},
		isNotConnected(){
			return !this.$store.getters.isConnected;
		},
		donationUrl(){
			window.open("https://paypal.me/lampadaire?country.x=BE&locale.x=fr_FR", "_blank", "name=Crypto-Don,toolbar=yes,scrollbars=yes,resizable=yes,top=200,left=500,width=600,height=1200");
		},
	}
}
</script>

<style scoped>
.custom-notification{
	margin-top: 80px;
	margin-right: 15px;
}
</style>