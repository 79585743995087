<template>
  <div class="wrapper faq-page">
    <div class="page-header espace-min-height">
      <div class="content">
        <img src="/img/path1.png" class="path">
        <img src="/img/path2.png" class="path2">

        <img src="/img/faq.svg" width="420" />

        <div class="container">

          <div class="row">
              <div class="col-lg-4">
                  <div class="nav nav-pills faq-nav" id="faq-tabs" role="tablist" aria-orientation="vertical">
                      <a href="#account" class="nav-link active" data-toggle="pill" role="tab" aria-controls="account" aria-selected="true">
                        <i class="fas fa-user"></i> Compte
                      </a>
                      <a href="#address" class="nav-link" data-toggle="pill" role="tab" aria-controls="address" aria-selected="false">
                        <i class="fas fa-link"></i> Adresse
                      </a>
                      <a href="#stream" class="nav-link" data-toggle="pill" role="tab" aria-controls="stream" aria-selected="false">
                        <i class="fas fa-headphones"></i> Stream
                      </a>
                      <a href="#don" class="nav-link" data-toggle="pill" role="tab" aria-controls="don" aria-selected="false">
                        <i class="fas fa-coins"></i> Don
                      </a>
                  </div>
              </div>
              <div class="col-lg-8">
                  <div class="tab-content" id="faq-tab-content">
                      <div class="tab-pane show active" id="account" role="tabpanel" aria-labelledby="account">
                          <div class="accordion" id="accordion-tab-1">
                              <div class="card">
                                  <div class="card-header" id="accordion-tab-1-heading-1">
                                      <h5>
                                          <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#accordion-account-content" aria-expanded="false" aria-controls="accordion-account-content">
                                            L'inscription est-elle gratuite ?
                                          </button>
                                      </h5>
                                  </div>
                                  <div class="collapse" id="accordion-account-content" aria-labelledby="accordion-tab-1-heading-1" data-parent="#accordion-tab-1">
                                      <div class="card-body">
                                          <p>Oui, l'inscription est complètement gratuite. Tu peux t'inscrire et profiter du système de donation en l'ajoutant à ton application de stream(obs, split, ...)</p>
                                          <p v-if="!this.$store.getters.isConnected">Tu peux t'inscrire <router-link to="/login">ici</router-link>.</p>
                                      </div>
                                  </div>
                              </div>
                              <div class="card">
                                  <div class="card-header" id="accordion-tab-1-heading-2">
                                      <h5>
                                          <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#accordion-tab-1-content-2" aria-expanded="false" aria-controls="accordion-tab-1-content-2">
                                            Puis-je modifier les informations de mon compte ?
                                          </button>
                                      </h5>
                                  </div>
                                  <div class="collapse" id="accordion-tab-1-content-2" aria-labelledby="accordion-tab-1-heading-2" data-parent="#accordion-tab-1">
                                      <div class="card-body">
                                          <p>Tu peux modifier les informations et le logo depuis ton <router-link to="/account">compte</router-link> en cliquant sur "Profil".</p>
                                          <p v-html="getTextForGuide()"></p>
                                      </div>
                                  </div>
                              </div>
                              <div class="card">
                                  <div class="card-header" id="accordion-tab-1-heading-3">
                                      <h5>
                                          <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#accordion-tab-1-content-3" aria-expanded="false" aria-controls="accordion-tab-1-content-3">
                                            Est-il possible de modifier mon mot de passe ?
                                          </button>
                                      </h5>
                                  </div>
                                  <div class="collapse" id="accordion-tab-1-content-3" aria-labelledby="accordion-tab-1-heading-3" data-parent="#accordion-tab-1">
                                      <div class="card-body">
                                          <p>Oui, n'hésites pas à modifier régulièrement ton mot de passe et à ne pas utiliser un mot de passe commun avec d'autres sites.</p>
                                          <p v-html="getTextForGuide()"></p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="tab-pane" id="address" role="tabpanel" aria-labelledby="address">
                          <div class="accordion" id="accordion-tab-2">
                              <div class="card">
                                  <div class="card-header" id="accordion-tab-2-heading-1">
                                      <h5>
                                          <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#accordion-tab-2-content-1" aria-expanded="false" aria-controls="accordion-tab-2-content-1">
                                            C'est quoi un memo ?
                                          </button>
                                      </h5>
                                  </div>
                                  <div class="collapse" id="accordion-tab-2-content-1" aria-labelledby="accordion-tab-2-heading-1" data-parent="#accordion-tab-2">
                                      <div class="card-body">
                                          <p>Un memo est requis lors de l'envoi d'une transaction vers une plateforme centralisée tel que Coinbase. De nombreux échanges n'utilisent qu'une ou quelques adresses de réception pour les dépôts. C'est pourquoi, ils ont besoin d'un memo afin qu'ils déterminent sur quel compte les fonds reçus seront envoyés.</p>
                                      </div>
                                  </div>
                              </div>
                              <div class="card">
                                  <div class="card-header" id="accordion-tab-2-heading-2">
                                      <h5>
                                          <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#accordion-tab-2-content-2" aria-expanded="false" aria-controls="accordion-tab-2-content-2">
                                            Comment avoir une adresse pour les crypto-monnaies disponible sur le site ?
                                          </button>
                                      </h5>
                                  </div>
                                  <div class="collapse" id="accordion-tab-2-content-2" aria-labelledby="accordion-tab-2-heading-2" data-parent="#accordion-tab-2">
                                      <div class="card-body">
                                          <p>Il existe plusieurs façons d'avoir des adresses crypto-monnaies. Le mieux serait de récupérer le passphrase lors de la création de ton adresse crypto-monnaie.</p><br>
                                          <p class="text-left">Les plateformes existantes avec un code de parrainage pour me soutenir :</p>
                                          <ul class="text-left">
                                            <li><a href="https://accounts.binance.com/fr/register?ref=FGYGM15W" target="_blank">Binance</a> (Nous gagnons tous les deux, 10% par transaction)</li>
                                            <li><a href="https://www.coinbase.com/join/rausin_b" target="_blank">Coinbase</a> (Lorsque tu auras fait des échanges pour 100$ ou plus, nous gagnerons tous les deux 10$ en Bitcoin)</li>
                                            <li><a href="https://join.swissborg.com/fr/r/julienHJ4X" target="_blank">SwissBorg</a> (Lorsque tu auras ajouté l'équivalent de 50€ en fiat (Euro, Dollars, ...) ou en crypto-monnaie, nous gagnerons tous les deux un Coupon Bonus pour gagner jusqu'à 100 €)</li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                              <div class="card">
                                  <div class="card-header" id="accordion-tab-2-heading-3">
                                      <h5>
                                          <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#accordion-tab-2-content-3" aria-expanded="false" aria-controls="accordion-tab-2-content-3">
                                            Pourquoi il n'y a pas toutes les crypto-monnaies ?
                                          </button>
                                      </h5>
                                  </div>
                                  <div class="collapse" id="accordion-tab-2-content-3" aria-labelledby="accordion-tab-2-heading-3" data-parent="#accordion-tab-2">
                                      <div class="card-body">
                                          <p>Nous allons ajouter des crypto-monnaies au fur et à mesure du temps. Il faut d'abord s'assurer que les crypto-monnaies rendent leur API disponible et surtout que les coûts de transaction soit résonnable.</p>
                                          <p>Si tu souhaites voir ta crypto-monnaie préférée, n'hésites pas à contacter le support depuis la page de contact.</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="tab-pane" id="stream" role="tabpanel" aria-labelledby="stream">
                          <div class="accordion" id="accordion-tab-3">
                              <div class="card">
                                  <div class="card-header" id="accordion-tab-3-heading-1">
                                      <h5>
                                          <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#accordion-tab-3-content-1" aria-expanded="false" aria-controls="accordion-tab-3-content-1">
                                              Sur quelle application de streaming puis-je recevoir les notifications de dons ?
                                          </button>
                                      </h5>
                                  </div>
                                  <div class="collapse" id="accordion-tab-3-content-1" aria-labelledby="accordion-tab-3-heading-1" data-parent="#accordion-tab-3">
                                      <div class="card-body">
                                          <p>Tant que ton application prend en charge les navigateurs (comme pour OBS, exemple ci-dessous)</p>
                                          <p class="text-left">
                                            <img src="/img/faq-navigateur-obs.png" alt="Image affichant le faite de pouvoir ajouter un navigateur sur OBS" />
                                          </p>
                                          <p>Il te suffit d'ajouter un navigateur et d'encoder une <b>largeur de 1920</b> et une <b>longueur de 1080</b>.</p>
                                          <p>Normalement OBS comprend que c'est un fond uni et le met directement transparent. (Si tu rencontres un problème, n'hésites pas à contacter le support depuis la page de contact)</p>
                                      </div>
                                  </div>
                              </div>
                              <div class="card">
                                  <div class="card-header" id="accordion-tab-3-heading-2">
                                      <h5>
                                          <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#accordion-tab-3-content-2" aria-expanded="false" aria-controls="accordion-tab-3-content-2">
                                              Est-ce grave si j'ai fais fuiter mon lien de stream ?
                                          </button>
                                      </h5>
                                  </div>
                                  <div class="collapse" id="accordion-tab-3-content-2" aria-labelledby="accordion-tab-3-heading-2" data-parent="#accordion-tab-3">
                                      <div class="card-body">
                                          <p>Aucun problème, cependant pour éviter que tout le monde utilise tes notifications lorsque tu reçois un don, il est préférable de le modifier, cependant aucune donnée de ton compte ne sera visible sur cette page.</p>
                                          <p>Tu peux simplement te rendre sur la page de ton compte et cliquer sur le bouton <a href="#" @click.stop.prevent=""><i class="fas fa-sync"></i></a> qui permet de régénérer un lien de stream (Après ça, il ne te reste plus qu'à le copier/coller dans ta source navigateur sur ton application de stream).</p>
                                      </div>
                                  </div>
                              </div>
                              <div class="card">
                                  <div class="card-header" id="accordion-tab-3-heading-3">
                                      <h5>
                                          <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#accordion-tab-3-content-3" aria-expanded="false" aria-controls="accordion-tab-3-content-3">
                                              Puis-je changer le son et l'animation lorsqu'il y a un don ?
                                          </button>
                                      </h5>
                                  </div>
                                  <div class="collapse" id="accordion-tab-3-content-3" aria-labelledby="accordion-tab-3-heading-3" data-parent="#accordion-tab-3">
                                      <div class="card-body">
                                          <p>Pour le moment, non. Cependant, tu pourras modifier le son et l'animation lors d'une prochaine mise à jour, l'idée est déjà notée 😉</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="tab-pane" id="don" role="tabpanel" aria-labelledby="don">
                          <div class="accordion" id="accordion-tab-4">
                              <div class="card">
                                  <div class="card-header" id="accordion-tab-4-heading-1">
                                      <h5>
                                          <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#accordion-tab-4-content-1" aria-expanded="false" aria-controls="accordion-tab-4-content-1">
                                            Est-ce que le site prend une part du don que je reçois ?
                                          </button>
                                      </h5>
                                  </div>
                                  <div class="collapse" id="accordion-tab-4-content-1" aria-labelledby="accordion-tab-4-heading-1" data-parent="#accordion-tab-4">
                                      <div class="card-body">
                                          <p>Non ! Le site te permet d'afficher ton adresse et le viewer la récupère afin de te faire un don.</p>
                                      </div>
                                  </div>
                              </div>
                              <div class="card">
                                  <div class="card-header" id="accordion-tab-4-heading-2">
                                      <h5>
                                          <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#accordion-tab-4-content-2" aria-expanded="false" aria-controls="accordion-tab-4-content-2">
                                            Les dons sont-ils anonymes ?
                                          </button>
                                      </h5>
                                  </div>
                                  <div class="collapse" id="accordion-tab-4-content-2" aria-labelledby="accordion-tab-4-heading-2" data-parent="#accordion-tab-4">
                                      <div class="card-body">
                                          <p>Oui, pour le moment les dons sont anonymes, cela veut dire que lorsque tu reçois un don, il n'y a que le montant qui s'affiche sur ton live.</p>
                                          <p>Plus tard, on te proposera un système pour que les viewers puissent afficher leur pseudo et un message.</p>
                                      </div>
                                  </div>
                              </div>
                              <div class="card">
                                  <div class="card-header" id="accordion-tab-4-heading-3">
                                      <h5>
                                          <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#accordion-tab-4-content-3" aria-expanded="false" aria-controls="accordion-tab-4-content-3">
                                            Comment faire un don ?
                                          </button>
                                      </h5>
                                  </div>
                                  <div class="collapse" id="accordion-tab-4-content-3" aria-labelledby="accordion-tab-4-heading-3" data-parent="#accordion-tab-4">
                                      <div class="card-body">
                                          <p>C'est très simple, il te suffit de copier l'adresse (ou de la scanner) de ton streamer et puis d'ouvrir ta plateforme avec ta crypto-monnaie et d'effectuer une transaction.</p>
                                          <p>Dès que la transaction arrive sur le compte du streamer, alors la notification sur le stream apparaitra.</p>
                                      </div>
                                  </div>
                              </div>
                              <div class="card">
                                  <div class="card-header" id="accordion-tab-4-heading-4">
                                      <h5>
                                          <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#accordion-tab-4-content-4" aria-expanded="false" aria-controls="accordion-tab-4-content-4">
                                            Est-ce que le site stocke mes dons ? Dois-je les retirer ?
                                          </button>
                                      </h5>
                                  </div>
                                  <div class="collapse" id="accordion-tab-4-content-4" aria-labelledby="accordion-tab-4-heading-4" data-parent="#accordion-tab-4">
                                      <div class="card-body">
                                          <p>Le site ne stocke pas d'argent, lorsque la personne te fait un don, ce don arrive directement sur ton adresse crypto-monnaie.</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default{
  mounted(){
    if(this.$route.query.help != null){
      switch(this.$route.query.help){
        case 'crypto':
          $("a[href='#address']").click();
          setTimeout(() => {
            $("#accordion-tab-2-content-2").addClass("show");
          }, 800);

          break;
      }
    }
  },
  methods: {
    getTextForGuide(){
      return "<strong>Guide: </strong>Un guide est disponible en dessous de ta page de compte. N'hésites pas à le lire (C'est rapide 👌)";
    }
  },
}
</script>

<style scoped>
body {
  margin-top: 30px;
  background-color: rgb(53, 53, 53);
}

.faq-nav {
  flex-direction: column;
  margin: 0 0 32px;
  border-radius: 2px;
  border: 1px solid #ddd;
  box-shadow: 0 1px 5px rgba(85, 85, 85, 0.15);
}
.faq-nav .nav-link {
  position: relative;
  display: block;
  margin: 0;
  padding: 13px 16px;
  border: 0;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  color: #ffffff;
  transition: background-color 0.2s ease;
}
.faq-nav .nav-link:hover {
  background-color: #f6f6f62d;
}
.faq-nav .nav-link.active {
  background-color: #f6f6f6af;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.87);
}
.faq-nav .nav-link:last-of-type {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom: 0;
}
.faq-nav .nav-link i.mdi {
  margin-right: 5px;
  font-size: 18px;
  position: relative;
}

.tab-content {
  box-shadow: 0 1px 5px rgba(85, 85, 85, 0.15);
}
.tab-content .card {
  border-radius: 0;
}
.tab-content .card-header {
  padding: 15px 16px;
  border-radius: 0;
}
.tab-content .card-header h5 {
  margin: 0;
}
.tab-content .card-header h5 button {
  display: block;
  width: 100%;
  padding: 0;
  border: 0;
  font-weight: 700;
  color: #ffffff;
  text-align: left;
  white-space: normal;
}
.tab-content .card-header h5 button:hover, .tab-content .card-header h5 button:focus, .tab-content .card-header h5 button:active, .tab-content .card-header h5 button:hover:active {
  text-decoration: none;
}
.tab-content .card-body p, .tab-content .card-body ul > li {
  color: #cacaca;
}
.tab-content .card-body p:last-of-type {
  margin: 0;
}
.accordion > .card:not(:first-child) {
  border-top: 0;
}
.collapse.show .card-body {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
</style>