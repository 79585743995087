<template>
    <h1 class="text-error-stream d-none"></h1>

    <div class="spinner-border-stream" id="spinner-loading" role="status">
        <span class="sr-only-stream">Loading...</span>
    </div>
</template>
<script>
import axios from 'axios';
import axiosRetry from 'axios-retry';

axiosRetry(axios, { 
    retries: 3, 
    retryDelay: (retryCount) => {
        return retryCount * 1000;
    }
});

/** Initialisation **/
const apiKeyCoinmarketcapPro = "365e7a2c-b7a2-47f9-b4b4-69f811614edd";
let storeObject = null;
let codeObject = null;

// TODO: Cryptos ADD
let AlgorandObject = null;
let StellarLumensObject = null;
let FluxObject = null;

let queueDonation = [];

export default{
    created(){
        $("body > nav").addClass("d-none");
        $("body > footer").addClass("d-none");
        $("body, html").css("overflow-y", "hidden");
        $("body, html").css("overflow-x", "hidden");
    },
    mounted(){
        storeObject = this.$store;
        codeObject = this.$route.params.code;

        let audio = new Audio("/soundDonation.mp3");
        let cryptoList = [];

        let adressExist = false;

        this.$store.dispatch('getAddressForSteam', { code: codeObject }).then((address) => {
            for (const [key, value] of Object.entries(address)) {
                if(value != null){
                    // TODO: Cryptos ADD
                    switch(key){
                        case "algo":
                            StellarLumensObject = new ALGO(value);
                            adressExist = true;
                            break;
                        case "xlm":
                            StellarLumensObject = new XLM(value);
                            adressExist = true;
                            break;
                        case "flux":
                            FluxObject = new FLUX(value);
                            adressExist = true;
                            break;
                    }
                }
            }

            // Si aucune adresse existe alors, l'afficher au streamer
            if(!adressExist){
                errorNoneAddress();
            }else{
                // Vérification des cryptos
                window.setInterval(function () {
                    // TODO: Cryptos ADD
                    if(AlgorandObject != null){
                        AlgorandObject.getDonation();
                    }
                    
                    if(StellarLumensObject != null){
                        StellarLumensObject.getDonation();
                    }
                    
                    if(FluxObject != null){
                        FluxObject.getDonation();
                    }
                }, 300 * 1000); // 5 minutes
            }
        }).catch(() => {
            errorWithCodeInUrl();
        });

        let cryptoObject = {
            wind : 0,
            maxXrange : 100,
            minXrange : 10,
            maxSpeed : 2,
            minSpeed : 1,
            maxSize : 40,
            minSize : 10,
            WIDTH : 0,
            HEIGHT : 0,
            init : function(){
                this.getSize();
            },
            random : function(range, num){
                let newNum = num ? num : 1;
                return Math.floor(Math.random() * (range + 1) * newNum) / newNum;
            },
            getSize : function(){
                this.WIDTH = document.body.clientWidth || window.innerWidth;
                this.HEIGHT = document.body.clientHeight || window.innerHeight;
            }
        };

        /** Call function **/
        cryptoObject.init();

        /** Function **/
        function callCrypto(donation){
            // Envoi des données dans la bd
            storeObject.dispatch('addTransaction', { code: codeObject, donation: donation });

            // Affichage
            let body = $("body");

            // On supprime les anciens
            $("#text-show-stream").remove();
            
            // Suppression des cryptos flottants
            for(let i = 0; i < cryptoList.length; i++){
                $(".crypt_"+i).remove();
            }

            // On vide la liste des cryptos
            cryptoList = [];

            // Jouer le son
            audio.play();

            // Affichage du texte
            let cryptoNameSpot = changeSpotText(donation.cryptoNameShort);
            body.append('<div id="text-show-stream" class=""><span class="class-text-before-crypto-stream">Merci pour ton don de '+ donation.donInCrypto +'<br>('+ donation.donInEur.toFixed(2) +'€) </span> <span class="crypto-name-stream">'+ cryptoNameSpot +'</span></div>');

            // Dans 10 secondes baisser l'opacité du texte
            setTimeout(function (){
                $("#text-show-stream").fadeTo("slow", 0);
            }, 10000);

            // Préparation des cryptos
            let frag = document.createDocumentFragment();

            // Récupère le nombre de crypto à afficher
            let nbrCrypto = donation.donInEur.toFixed(0) < 1 ? 1 : Math.ceil(donation.donInEur);

            for(let i = 0; i < nbrCrypto; i++){
                let crypto = {
                    x : cryptoObject.random(cryptoObject.WIDTH),
                    y : - cryptoObject.maxSize,
                    xrange : cryptoObject.minXrange + cryptoObject.random(cryptoObject.maxXrange - cryptoObject.minXrange),
                    yspeed : cryptoObject.minSpeed + cryptoObject.random(cryptoObject.maxSpeed - cryptoObject.minSpeed, 100),
                    life : 0,
                    size : cryptoObject.minSize + cryptoObject.random(cryptoObject.maxSize - cryptoObject.minSize),
                    html : document.createElement("span")
                };

                crypto.html.style.position = "absolute";
                crypto.html.style.top = crypto.y + "px";
                crypto.html.style.left = crypto.x + "px";
                crypto.html.style.fontSize = crypto.size + "px";
                crypto.html.style.color = cryptoObject.color;
                crypto.html.className = "crypt_" + i;

                let imgCrypto = document.createElement("img");
                imgCrypto.src = "/img/crypto/"+ donation.cryptoNameShort +".png";
                imgCrypto.width = 30;
                imgCrypto.height = 30;
                crypto.html.appendChild(imgCrypto);

                frag.appendChild(crypto.html)
                cryptoList.push(crypto);
            }

            document.body.appendChild(frag);

            // Animation
            for(let i = 0; i < cryptoList.length; i++){
                animation(i);
            }
        }

        function animation(i){
            let test = true;
            let cryptoAnimate = cryptoList[i];

            let top = cryptoAnimate.y + cryptoAnimate.yspeed;
            let left = cryptoAnimate.x + Math.sin(cryptoAnimate.life) * cryptoAnimate.xrange + cryptoObject.wind;

            setTimeout(function(){
                if(top < cryptoObject.HEIGHT - cryptoAnimate.size && left < cryptoObject.WIDTH - cryptoAnimate.size && left > 0){
                    cryptoAnimate.html.style.top = top + "px";
                    cryptoAnimate.html.style.left = left + "px";
                    cryptoAnimate.y = top;
                    cryptoAnimate.x += cryptoObject.wind;
                    cryptoAnimate.life+= .01;
                }else{
                    setTimeout(function(){
                        $(".crypt_"+i).fadeTo("slow", 0);
                    },1000);

                    test = false;
                }

                if(test){
                    animation(i);
                }
            },20);
        }

        // Affichage du nom de la crypto
        function changeSpotText(cryptoName) {
            switch(cryptoName){
                case "xlm":
                    return 'St<span class="fast-flicker-stream">e</span>llar Lu<span class="flicker-stream">m</span>ens';
                case "algo":
                    return 'Alg<span class="fast-flicker-stream">or</span>and';
            }
            return "";
        }

        // Si il y a une donnée dans la queue on la retire et on l'affiche à l'écran
        window.setInterval(function () {
            if(queueDonation.length > 0){
                let donation = queueDonation.shift();
                
                callCrypto(donation); // Affichage du don sur l'écran
            }
        }, 50000);
    },
}

/******************************************************************************************************************************************************************
 * ALGO
 ******************************************************************************************************************************************************************/
    class ALGO {
        constructor(address){
            this.address = address;
            this.balanceCrypto = 0.0;
            this.last_id = "";

            this.getAccountData().then((account) => {
                // On mets à jour la balanceCrypto
                this.balanceCrypto = parseFloat(account.amount) / 1000000;

                // On récupère l'id de la dernière transaction
                this.getTransactions(1).then((transactions) => {
                    this.last_id = transactions[0].id;
                });

                $("#spinner-loading").remove();
            }).catch(() => {
                errorWithAddress("Algorand");
            });
        }

        getAccountData(){
            return new Promise((resolve, reject) => {
                axios.get('https://algoindexer.algoexplorerapi.io/v2/accounts/'+ this.address).then((response) => {
                    resolve(response.data.account);
                }).catch((err) => {
                    reject();
                });
            });
        }

        getTransactions(number){
            return new Promise((resolve, reject) => {
                axios.get('https://algoindexer.algoexplorerapi.io/v2/transactions?address='+ this.address +'&limit='+ number +'&address-role=receiver').then((response) => {
                    resolve(response.data.transactions);
                }).catch((err) => {
                    reject();
                });
            });
        }

        getDonation(){
            this.getTransactions(15).then((transactions) => {
                this.getEuroForCrypto().then((priceForOneCrypto) => {                
                    for (let i = 0; i < transactions.length; i++){
                        let transaction = transactions[i]; // Récupération de la transaction

                        if(this.last_id == transaction.id){ // Si l'id courant est le même que la transaction courante, on s'arrête ici
                            this.balance += parseFloat(transaction["payment-transaction"].amount) / 1000000; // Mise à jour de la balance
                            this.last_id = transactions[0].id; // Récupère le premier élément de la liste ce qui permet de garder la dernière transaction

                            break;
                        }else{ // Sinon on l'ajoute dans la queue
                            let donInCrypto = parseFloat(transaction["payment-transaction"].amount / 1000000);

                            let donInEur = donInCrypto * priceForOneCrypto; // Mutiliplication du nombre de crypto * le prix en euro pour savoir combien vaut le prix en €

                            queueDonation.push({
                                donInEur: donInEur,
                                donInCrypto: donInCrypto,
                                cryptoName: "Algorand",
                                cryptoNameShort: "algo",
                                date : new Date().getTime(),
                                id: transaction.id + ""
                            });
                        }
                    }
                });
            });
        }

        getEuroForCrypto(){ // Todo add https://min-api.cryptocompare.com/documentation
            return new Promise((resolve, reject) => {
                axios.get('https://api.coingecko.com/api/v3/simple/price?ids=algorand&vs_currencies=eur').then((resp) => {
                    resolve(resp.data.algorand.eur);
                }).catch((err) => {
                    // Retry on another api
                    axios.get('https://api.coinmarketcap.com/data-api/v3/tools/price-conversion?amount=1&convert_id=2790&id=4030').then((resp) => {
                        resolve(resp.data.data.quote[0].price);
                    }).catch((err) => {
                        // Retry on another api
                        axios.get('https://pro-api.coinmarketcap.com/v1/tools/price-conversion?amount=1&convert=EUR&id=2790', {headers: {"X-CMC_PRO_API_KEY": apiKeyCoinmarketcapPro}}).then((resp) => {
                            resolve(resp.data.data.quote.EUR.price);
                        }).catch((err) => {
                            // Retry on another api
                            axios.get('https://api.cryptonator.com/api/ticker/algo-eur').then((resp) => {
                                resolve(resp.data.ticker.price);
                            }).catch((err) => {
                                // Send logs
                                sendWebhook(`**Object**: [getEuroForCrypto] ${this.constructor.name} \n\n**Code**: ${codeObject}\n\n**Address**: ${this.address}\n\n**Last_id**: ${this.last_id}\n\n**Error**:\n${err}`);
                            });
                        });
                    });
                });
            });
        }
    }

/******************************************************************************************************************************************************************
 * XLM
 ******************************************************************************************************************************************************************/
    class XLM {
        constructor(address){
            this.address = address;
            this.balanceCrypto = 0.0;
            this.last_id = "";

            this.getAccountData().then((account) => {
                // On mets à jour la balanceCrypto
                this.balanceCrypto = parseFloat(account.balances[0].balance);

                // On récupère l'id de la dernière transaction
                this.getTransactions(1).then((transactions) => {
                    this.last_id = transactions[0].id;
                });

                $("#spinner-loading").remove();
            }).catch(() => {
                errorWithAddress("Stellar Lumens");
            });
        }

        getAccountData(){
            return new Promise((resolve, reject) => {
                axios.get('https://horizon.stellar.org/accounts/'+ this.address).then((response) => {
                    resolve(response.data);
                }).catch((err) => {
                    reject();
                });
            });
        }

        getTransactions(number){
            return new Promise((resolve, reject) => {
                axios.get('https://horizon.stellar.org/accounts/'+ this.address +'/payments?limit='+ number +'&order=desc').then((response) => {
                    resolve(response.data._embedded.records);
                }).catch((err) => {
                    reject();
                });
            });
        }

        getDonation(){
            this.getTransactions(20).then((transactions) => {
                this.getEuroForCrypto().then((priceForOneCrypto) => {                   
                    for (let i = 0; i < transactions.length; i++){
                        let transaction = transactions[i]; // Récupération de la transaction

                        if(this.last_id == transaction.id){ // Si l'id courant est le même que la transaction courante, on s'arrête ici
                            this.balance += parseFloat(transaction.amount); // Mise à jour de la balance
                            this.last_id = transactions[0].id; // Récupère le premier élément de la liste ce qui permet de garder la dernière transaction

                            break;
                        }else{ // Sinon on l'ajoute dans la queue
                            if(transaction.transaction_successful == true && transaction.type == "payment" && transaction.source_account !== this.address){ // Vérifie que l'adresse qui envoie n'est pas la nôtre
                                let donInCrypto = parseFloat(transaction.amount);

                                let donInEur = donInCrypto * priceForOneCrypto; // Mutiliplication du nombre de crypto * le prix en euro pour savoir combien vaut le prix en €

                                queueDonation.push({
                                    donInEur: donInEur,
                                    donInCrypto: donInCrypto,
                                    cryptoName: "Stellar Lumens",
                                    cryptoNameShort: "xlm",
                                    date : new Date().getTime(),
                                    id: transaction.id + ""
                                });
                            }
                        }
                    }
                });
            });
        }

        getEuroForCrypto(){
            return new Promise((resolve, reject) => {
                axios.get('https://api.coingecko.com/api/v3/simple/price?ids=stellar&vs_currencies=eur').then((resp) => {
                    resolve(resp.data.stellar.eur);
                }).catch((err) => {
                    // Retry on another api
                    axios.get('https://api.coinmarketcap.com/data-api/v3/tools/price-conversion?amount=1&convert_id=2790&id=512').then((resp) => {
                        resolve(resp.data.data.quote[0].price);
                    }).catch((err) => {
                        // Retry on another api
                        axios.get('https://pro-api.coinmarketcap.com/v1/tools/price-conversion?amount=1&convert=EUR&id=512', {headers: {"X-CMC_PRO_API_KEY": apiKeyCoinmarketcapPro}}).then((resp) => {
                            resolve(resp.data.data.quote.EUR.price);
                        }).catch((err) => {
                            // Retry on another api
                            axios.get('https://api.cryptonator.com/api/ticker/xlm-eur').then((resp) => {
                                resolve(resp.data.ticker.price);
                            }).catch((err) => {
                                // Send logs
                                sendWebhook(`**Object**: [getEuroForCrypto] ${this.constructor.name} \n\n**Code**: ${codeObject}\n\n**Address**: ${this.address}\n\n**Last_id**: ${this.last_id}\n\n**Error**:\n${err}`);
                            });
                        });
                    });
                });
            });
        }
    }

/******************************************************************************************************************************************************************
 * FLUX
 ******************************************************************************************************************************************************************/
    class FLUX {
        constructor(address){
            this.address = address;
            this.balanceCrypto = 0.0;
            this.last_id = "";

            this.getAccountData().then((account) => {
                // On mets à jour la balanceCrypto
                this.balanceCrypto = (account.data == null ? 0.0 : parseFloat(account.data / 100000000));

                // On récupère l'id de la dernière transaction
                this.getTransactions().then((transactions) => {
                    this.last_id = transactions[transactions.length].txid;
                });

                $("#spinner-loading").remove();
            }).catch(() => {
                errorWithAddress("Flux");
            });
        } 

        getAccountData(){
            return new Promise((resolve, reject) => {
                axios.get('https://api.runonflux.io/explorer/balance?address='+ this.address).then((response) => {
                    resolve(response.data);
                }).catch((err) => {
                    reject();
                });
            });
        }

        getTransactions(){ 
            return new Promise((resolve, reject) => {
                axios.get('https://api.runonflux.io/explorer/utxo?address='+ this.address).then((response) => {
                    resolve(response.data.data);
                }).catch((err) => {
                    reject();
                });
            });
        }

        getDonation(){ 
            this.getTransactions().then((transactions) => {
                this.getEuroForCrypto().then((priceForOneCrypto) => {
                    for (let i = transactions.length; i > 0; i--){
                        let transaction = transactions[i]; // Récupération de la transaction

                        if(this.last_id == transaction.txid){ // Si l'id courant est le même que la transaction courante, on s'arrête ici
                            this.balance += parseFloat(transaction.satoshis / 100000000); // Mise à jour de la balance
                            this.last_id = transactions[0].txid; // Récupère le premier élément de la liste ce qui permet de garder la dernière transaction

                            break;
                        }else{ // Sinon on l'ajoute dans la queue
                            if(transaction.address === this.address){ // Vérifie que l'adresse qui reçoit est la nôtre
                                let donInCrypto = parseFloat(transaction.satoshis / 100000000);

                                let donInEur = donInCrypto * priceForOneCrypto; // Mutiliplication du nombre de crypto * le prix en euro pour savoir combien vaut le prix en €

                                queueDonation.push({
                                    donInEur: donInEur,
                                    donInCrypto: donInCrypto,
                                    cryptoName: "Flux",
                                    cryptoNameShort: "flux",
                                    date : new Date().getTime(),
                                    id: transaction.txid + ""
                                });
                            }
                        }
                    }
                });
            });
        }

        getEuroForCrypto(){
            return new Promise((resolve, reject) => {
                axios.get('https://api.coingecko.com/api/v3/simple/price?ids=zelcash&vs_currencies=eur').then((resp) => {
                    resolve(resp.data.flux.eur);
                }).catch((err) => {
                    // Retry on another api
                    axios.get('https://api.coinmarketcap.com/data-api/v3/tools/price-conversion?amount=1&convert_id=2790&id=3029').then((resp) => {
                        resolve(resp.data.data.quote[0].price);
                    }).catch((err) => {
                        // Retry on another api
                        axios.get('https://pro-api.coinmarketcap.com/v1/tools/price-conversion?amount=1&convert=EUR&id=3029', {headers: {"X-CMC_PRO_API_KEY": apiKeyCoinmarketcapPro}}).then((resp) => {
                            resolve(resp.data.data.quote.EUR.price);
                        }).catch((err) => {
                            // Retry on another api
                            axios.get('https://api.cryptonator.com/api/ticker/flux-eur').then((resp) => {
                                resolve(resp.data.ticker.price);
                            }).catch((err) => {
                                // Send logs
                                sendWebhook(`**Object**: [getEuroForCrypto] ${this.constructor.name} \n\n**Code**: ${codeObject}\n\n**Address**: ${this.address}\n\n**Last_id**: ${this.last_id}\n\n**Error**:\n${err}`);
                            });
                        });
                    });
                });
            });
        }
    }

/******************************************************************************************************************************************************************
 * ATOM
 ******************************************************************************************************************************************************************/
    class ATOM {
        constructor(address){
            this.address = address;
            this.balanceCrypto = 0.0;
            this.last_id = "";

            this.getAccountData().then((amount) => {
                // On mets à jour la balanceCrypto
                this.balanceCrypto = parseFloat(amount / 1000000);

                // On récupère l'id de la dernière transaction
                this.getTransactions().then((transactions) => {
                    this.last_id = transactions[transactions.length].txhash;
                });

                $("#spinner-loading").remove();
            }).catch(() => {
                errorWithAddress("Flux");
            });
        } 

        getAccountData(){
            return new Promise((resolve, reject) => {
                axios.get('https://api.cosmos.network/bank/balances/'+ this.address).then((response) => {
                    for(let i = 0; i < response.data.result.length; i++){
                        if(response.data.result[i].denom == "uatom"){
                            resolve(response.data.result[i].amount);
                        }
                    }

                    reject();
                }).catch((err) => {
                    reject();
                });
            });
        }

        getTransactions(){
            return new Promise((resolve, reject) => {
                axios.get('https://api.cosmos.network/txs?transfer.recipient='+ this.address +'&message.module=bank&limit=10&page=1').then((response) => {
                    if(parseInt(response.data.total_count) <= parseInt(response.data.limit)){
                        resolve(response.data.txs);
                    }else{
                        axios.get('https://api.cosmos.network/txs?transfer.recipient='+ this.address +'&message.module=bank&limit=10&page='+ response.data.page_total).then((responseSecond) => {
                            resolve(responseSecond.data.txs);
                        }).catch((err) => {
                            reject();
                        });
                    }
                }).catch((err) => {
                    reject();
                });
            });
        }

        getDonation(){
            this.getTransactions().then((transactions) => {
                this.getEuroForCrypto().then((priceForOneCrypto) => { 
                    for (let i = transactions.length; i > 0; i--){
                        let transaction = transactions[i]; // Récupération de la transaction

                        if(this.last_id == transaction.txhash){ // Si l'id courant est le même que la transaction courante, on s'arrête ici
                            for (let j = 0; j < transaction.logs.length; i++){
                                if(transaction.logs[j].type == "coin_received"){
                                    if(transaction.logs[j].attributes[0].key == "receiver"){
                                        if(transaction.logs[j].attributes[0].value == this.address){
                                            this.balance += parseFloat(parseInt(transaction.logs[j].attributes[1].value.replace("uatom", "")) / 1000000); // Mise à jour de la balance
                                            this.last_id = transactions[0].txhash; // Récupère le premier élément de la liste ce qui permet de garder la dernière transaction
                                            break;
                                        }
                                    }else{
                                        if(transaction.logs[j].attributes[1].value == this.address){
                                            this.balance += parseFloat(parseInt(transaction.logs[j].attributes[0].value.replace("uatom", "")) / 1000000); // Mise à jour de la balance
                                            this.last_id = transactions[0].txhash; // Récupère le premier élément de la liste ce qui permet de garder la dernière transaction
                                            break;
                                        }
                                    }
                                }
                            }

                            break;
                        }else{ // Sinon on l'ajoute dans la queue
                            for (let j = 0; j < transaction.logs.length; i++){
                                if(transaction.logs[j].type == "coin_received"){
                                    let donInCrypto = 0;

                                    if(transaction.logs[j].attributes[0].key == "receiver"){
                                        if(transaction.logs[j].attributes[0].value == this.address){
                                            donInCrypto = parseFloat(parseInt(transaction.logs[j].attributes[1].value.replace("uatom", "")) / 1000000);

                                        }
                                    }else{
                                        if(transaction.logs[j].attributes[1].value == this.address){
                                            donInCrypto = parseFloat(parseInt(transaction.logs[j].attributes[0].value.replace("uatom", "")) / 1000000);
                                        }
                                    }

                                    let donInEur = donInCrypto * priceForOneCrypto; // Mutiliplication du nombre de crypto * le prix en euro pour savoir combien vaut le prix en €

                                    queueDonation.push({
                                        donInEur: donInEur,
                                        donInCrypto: donInCrypto,
                                        cryptoName: "Atom",
                                        cryptoNameShort: "atom",
                                        date : new Date().getTime(),
                                        id: transaction.txhash + ""
                                    });

                                    break;
                                }
                            }
                        }
                    }
                });
            });
        }

        getEuroForCrypto(){
            return new Promise((resolve, reject) => {
                axios.get('https://api.coingecko.com/api/v3/simple/price?ids=cosmos&vs_currencies=eur').then((resp) => {
                    resolve(resp.data.cosmos.eur);
                }).catch((err) => {
                    // Retry on another api
                    axios.get('https://api.coinmarketcap.com/data-api/v3/tools/price-conversion?amount=1&convert_id=2790&id=3794').then((resp) => {
                        resolve(resp.data.data.quote[0].price);
                    }).catch((err) => {
                        // Retry on another api
                        axios.get('https://pro-api.coinmarketcap.com/v1/tools/price-conversion?amount=1&convert=EUR&id=3794', {headers: {"X-CMC_PRO_API_KEY": apiKeyCoinmarketcapPro}}).then((resp) => {
                            resolve(resp.data.data.quote.EUR.price);
                        }).catch((err) => {
                            // Retry on another api
                            axios.get('https://api.cryptonator.com/api/ticker/atom-eur').then((resp) => {
                                resolve(resp.data.ticker.price);
                            }).catch((err) => {
                                // Send logs
                                sendWebhook(`**Object**: [getEuroForCrypto] ${this.constructor.name} \n\n**Code**: ${codeObject}\n\n**Address**: ${this.address}\n\n**Last_id**: ${this.last_id}\n\n**Error**:\n${err}`);
                            });
                        });
                    });
                });
            });
        }
    }

/******************************************************************************************************************************************************************
 * Function
 ******************************************************************************************************************************************************************/
    /**
     * Permet d'afficher au streamer que son adresse de stream n'est pas correcte
     */
    function errorWithCodeInUrl(){
        $(".text-error-stream").html(`Crypto-Don<br>
                                        L'url n'est pas correcte ! <br>
                                        régénére ton url pour le stream depuis ton compte, puis rafraîchi le navigateur(obs).`);
        $(".text-error-stream").removeClass("d-none");

        setNullAdresses();
    }

    /**
     * Permet d'afficher au streamer l'erreur avec l'adresse passé en paramètre mais aussi de supprimer les objets
     * @param {String} nameAdress 
     */
    function errorWithAddress(nameAdress){
        $(".text-error-stream").html(`Crypto-Don<br>
                                        L'adresse ${nameAdress} est introuvable ! <br>
                                        Modifie ou supprime l'adresse depuis ton compte, puis rafraîchi le navigateur(obs).`);
        $(".text-error-stream").removeClass("d-none");

        setNullAdresses();
    }

    /**
     * Permet d'afficher au streamer qu'il n'a aucune adresse encodée sur son compte
     */
    function errorNoneAddress(){
        $(".text-error-stream").html(`Crypto-Don<br>
                                        Aucune adresse liée à ton compte ...<br>
                                        Ajoute des adresses depuis ton compte, puis rafraîchi le navigateur(obs).`);
        $(".text-error-stream").removeClass("d-none");
    }

    function setNullAdresses(){
        AlgorandObject = null;
        StellarLumensObject = null;
    }
    
    function sendWebhook(content){
        axios.post('https://discord.com/api/webhooks/1006487115011473498/5_NIMYjIzvY57iqzK4ngdJMO0lYvhdqWqRkEPhuobFVy7BeHblI55TmZBPrgPhTEHR7E', {"content": content}, {"headers": {"Content-Type": "application/json"}});
    }
</script>