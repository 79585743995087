<template>
    <div class="col-12 col-md-6" v-if="!addressIsNull()">
        <div class="card">
            <div class="card-body text-center">
                <button type="button" v-if="!isForViewerPage() && this.$store.getters.isConnected" class="btn btn-simple btn-primary btn-icon btn-round btn-delete-address" @click.stop.prevent="deleteAddress()" v-tooltip.top="'Supprimer l\'adresse'"><i class="fas fa-trash"></i></button>
                <button type="button" v-if="!isForViewerPage() && this.$store.getters.isConnected" class="btn btn-simple btn-primary btn-icon btn-round btn-update-address" @click.stop.prevent="updateAddress()" v-tooltip.top="'Modifier l\'adresse'"><i class="fas fa-pencil-alt"></i></button>

                <img v-bind:src="'/img/crypto/'+ cryptoName +'.png'" class="img-crypto">
                <h4>{{ this.$store.getters.getUserCrypto[cryptoName].name }}</h4>

                <hr>

                <div v-bind:id="'place-'+ cryptoName">
                    <div class="the-spinner" v-bind:id="'spinner-'+ cryptoName">
                        <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                    <img>
                    <br>
                    <a href="#" class="badge badge-default link-address-click" @click.stop.prevent="copyAddress()" v-tooltip.top="'Copier l\'adresse'">
                        <i class="fas fa-copy"></i> {{ displayAddress() }}
                    </a>
                    <p>
                        Memo :
                        <span v-if="ifMemoExist()">
                            <a href="#" class="badge badge-primary" @click.stop.prevent="copyMemo()" v-tooltip.bottom="'Copier le memo'"><i class="fas fa-copy"></i> {{ displayMemo() }}</a>
                        </span>
                        <span v-else>
                            <span class="badge badge-info" v-tooltip.bottom="'Il n\'y a pas besoin d\'indiquer le memo'">Pas de memo</span>
                        </span>
                    </p>
                </div>

                <div v-bind:id="'place-update-'+ cryptoName" class="text-left d-none">
                    <div class="form-group">
                        <div class="input-group input-group-alternative">
                            <input class="form-control" v-bind:id="'input-place-'+ cryptoName" placeholder="Ton adresse" type="text">
                        </div>
                        <div class="input-group input-group-alternative">
                            <input class="form-control" v-bind:id="'input-place-memo-'+ cryptoName" v-bind:placeholder="trueIfMemoExist(cryptoName) ? 'Ton memo' : 'Pas besoin de memo'" type="text" :disabled="!trueIfMemoExist(cryptoName)">
                        </div>
                    </div>
                    <p class="message-error text-center" v-bind:id="'message-view-error-'+ cryptoName"></p>
                    <button type="button" class="btn btn-simple btn-icon btn-round" @click.stop.prevent="cancelUpdate()" v-tooltip.bottom="'Annuler la modification'"><i class="fas fa-times"></i></button>
                    <button type="button" class="btn btn-simple btn-primary btn-icon btn-round float-right" v-bind:id="'btn-token-update-'+ cryptoName" @click.stop.prevent="updateConfirmAddress()" v-tooltip.bottom="'Sauvegarder la modification'"><i class="fas fa-paper-plane"></i></button>
                </div>

            </div>
        </div>
    </div>

</template>

<script>
import methods from '../methods';
import copy from 'copy-text-to-clipboard';
import QRCode from 'qrcode';
import { notify } from "@kyvg/vue3-notification";

export default({
    created(){
      this.trueIfMemoExist = methods.trueIfMemoExist;
    },
    props: ['cryptoName'],
    mounted() {
        this.$store.subscribe(({ type }) => {
            switch (type) {
                case "addAddress":
                    this.updateQRCode();

                    break;
            }
        });
    },
    methods: {
        updateQRCode(){
            let url = this.$store.getters.getUserCrypto[this.cryptoName].address;
            QRCode.toDataURL(url)
                .then(url => {
                    $("#spinner-"+ this.cryptoName).addClass("d-none");

                    $("#place-"+ this.cryptoName+" > img").attr("src", url);
                })
                .catch(err => {
                    $("#spinner-"+ this.cryptoName).html(`<span class="badge badge-warning">Erreur lors du chargement du QR Code ...</span>`);
                    $("#spinner-"+ this.cryptoName).removeClass("d-none");
                });
        },
        copyAddress(){
            copy(this.$store.getters.getUserCrypto[this.cryptoName].address);
            
            notify({
                text: "📝 L'adresse a été copié !",
                type: "success"
            });
        },
        isForViewerPage(){
            return this.$route.params.id != undefined;
        },
        cryptosIsNull () {
            return this.$store.getters.isConnected && this.addressIsNull();
        },
        addressIsNull() {
            return this.$store.getters.getUserCrypto[this.cryptoName].address == null;
        },
        updateAddress(){
            $("#input-place-"+ this.cryptoName).removeClass("is-invalid");
            $("#message-view-error-"+ this.cryptoName).text("");
            $("#input-place-"+ this.cryptoName).val(this.$store.getters.getUserCrypto[this.cryptoName].address);
            if(this.trueIfMemoExist(this.cryptoName)){
                $("#input-place-memo-"+ this.cryptoName).val(this.$store.getters.getUserCrypto[this.cryptoName].memo);
            }

            $("#place-"+ this.cryptoName).fadeOut('slow', () => {
                $("#place-update-"+ this.cryptoName).removeClass("d-none");
            });
        },
        updateConfirmAddress(){
            let address = $("#input-place-"+ this.cryptoName).val().trim();
            let memo = $("#input-place-memo-"+ this.cryptoName).val().trim();

            $("#message-view-error-"+ this.cryptoName).text("");
            $("#btn-token-update-"+ this.cryptoName).addClass("disabled");

            methods.testCryptoAddress(this.cryptoName, address).then(() => {
                this.$store.dispatch('updateAddress', {
                    name: this.cryptoName,
                    address: address,
                    memo: memo,
                }).then(() => {
                    this.updateQRCode();
                    
                    notify({
                        text: "📬 Ton adresse a bien été modifiée !",
                        type: "success"
                    });

                    $("#place-"+ this.cryptoName).fadeIn();
                    $("#place-update-"+ this.cryptoName).addClass("d-none");
                }).finally(() => {
                    $("#btn-token-update-"+ this.cryptoName).removeClass("disabled");
                });
            }).catch(() => {
                $("#input-place-"+ this.cryptoName).addClass("is-invalid");

                $("#message-view-error-"+ this.cryptoName).text("Votre adresse n'existe pas.");

                $("#btn-token-update-"+ this.cryptoName).removeClass("disabled");
            });
        },
        cancelUpdate(){
            $("#place-"+ this.cryptoName).fadeIn();
            $("#place-update-"+ this.cryptoName).addClass("d-none");
        },
        displayAddress(){
            this.updateQRCode();

            return this.$store.getters.getUserCrypto[this.cryptoName].address.slice(0, 15) + "..." + this.$store.getters.getUserCrypto[this.cryptoName].address.slice(-15);
        },
        deleteAddress(){
            this.$store.dispatch('deleteAddress', { name: this.cryptoName }).then(() =>{
                notify({
                    text: "📭 Ton adresse a bien été supprimée !",
                    type: "success"
                });
            });
        },
        ifMemoExist(){
            return methods.trueIfMemoExist(this.cryptoName) && this.$store.getters.getUserCrypto[this.cryptoName].memo != null;
        },
        displayMemo(){
            return this.$store.getters.getUserCrypto[this.cryptoName].memo;
        },
        copyMemo(){
            copy(this.$store.getters.getUserCrypto[this.cryptoName].memo);
            
            notify({
                text: "📝 Le memo a été copié !",
                type: "success"
            });
        }
    }
});
</script>

<style scoped>
  input[disabled]{
    background-color: transparent !important;
  }
  .img-crypto{
    width: 80px !important;
  }
  .btn-update-address{
    position: absolute;
    top: 5px;
    right: 10px;
  }
  .btn-delete-address{
    position: absolute;
    top: 5px;
    left: 10px;
  }
  .link-address-click{
      margin-top: 10px;
      font-size: 12px;
  }
  .the-spinner{
      width: 100%;
  }
  .message-error{
    color: tomato;
    font-size: 80%;
  }
</style>