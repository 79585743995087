import { createRouter, createWebHistory } from 'vue-router';
import indexVue from '../components/index.vue';
import loginVue from '../components/login.vue';
import faqVue from '../components/faq.vue';
import contactVue from '../components/contact.vue';
import resetPasswordVue from '../components/reset-password.vue';
import cguVue from '../components/cgu.vue';
import accountVue from '../components/account.vue';
import streamPageVue from '../components/stream-page.vue';

const routes = [
    { 
        path: '/', 
        name: 'Home', 
        component: indexVue 
    },
    { 
        path: '/faq', 
        name: 'FAQ', 
        component: faqVue
    },
    { 
        path: '/contact', 
        name: 'Contact', 
        component: contactVue
    },
    { 
        path: '/cgu', 
        name: 'CGU', 
        component: cguVue
    },
    { 
        path: '/login', 
        name: 'Login', 
        component: loginVue 
    },
    { 
        path: '/password/reset/:code', 
        name: 'ResetPassword', 
        component: resetPasswordVue, 
        meta: { 
            requiresAuth: false 
        } 
    },
    { 
        path: '/account', 
        name: 'Account', 
        component: accountVue, 
        meta: { 
            requiresAuth: true 
        } 
    },
    { 
        path: '/account/:id', 
        name: 'Account Page', 
        component: accountVue
    },
    { 
        path: '/stream/:code', 
        name: 'Stream', 
        component: streamPageVue
    },
    {
        path: '/:catchAll(.*)',
        redirect: '/'
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;