<template>
  <div class="wrapper account-page">
    <div class="main">      
      <img src="/img/path1.png" class="path">

      <div class="container page-header align-items-center">
        <div class="row espace-div-top">

          <div class="col-12 col-lg-4">
            <h1 class="profile-title text-left pl-2">{{ displayPseudo() }}</h1>
            <p class="profile-description p-2" v-if="textIsExist()">{{ displayProfilText() }}</p>
            <div class="btn-wrapper profile pt-3 p-2">
              <a target="_blank" v-bind:href="'https://twitter.com/' + displayPseudoTwitter()" v-if="displayPseudoTwitterIf()" class="btn btn-icon btn-twitter btn-round mr-2" v-tooltip.bottom="'Mon Twitter'">
                <i class="fab fa-twitter"></i>
              </a>
              <a target="_blank" v-bind:href="displayLinkFacebook()" v-if="displayLinkFacebookIf()" class="btn btn-icon btn-facebook btn-round mr-2" v-tooltip.bottom="'Mon Facebook'">
                <i class="fab fa-facebook-square"></i>
              </a>
              <a target="_blank" v-bind:href="displayLinkYoutube()" v-if="displayLinkYoutubeIf()" class="btn btn-icon btn-youtube btn-round mr-2" v-tooltip.bottom="'Mon Youtube'">
                <i class="fab fa-youtube-square"></i>
              </a>
              <a target="_blank" v-bind:href="displayLinkTiktok()" v-if="displayLinkTiktokIf()" class="btn btn-icon btn-tiktok btn-round mr-2" v-tooltip.bottom="'Mon Tiktok'">
                <i class="fab fa-tiktok"></i>
              </a>
              <a target="_blank" v-bind:href="'https://twitch.com/' + displayPseudoTwitch()" v-if="displayPseudoTwitchIf()" class="btn btn-icon btn-twitch btn-round" v-tooltip.bottom="'Ma chaine Twitch'">
                <i class="fab fa-twitch"></i>
              </a>
            </div>
          </div>

          <div class="col-12 col-lg-8">
            <img src="/img/path5.png" class="path path-in">

            <div class="btn-add-address" v-if="!isForViewerPage()">
              <button type="button" class="btn btn-simple btn-primary btn-icon btn-round" data-toggle="modal" data-target="#modal-add-address" v-tooltip.top="'Ajouter une adresse'"><i class="fas fa-plus"></i></button>
              <span class="badge badge-primary badge-nbr-add-address">{{ getNbrOfAddressAvailable() }}</span>
            </div>
            
            <div class="card card-coin card-plain pr-4">
              <div class="card-header">
                <img v-bind:src="'/img/user/'+ displayLogo()" @click.stop.prevent="clickChangeImg()" v-tooltip="isForViewerPage() ? '' : 'Modifier ton logo (de préférence un carré)'" class="img-center img-fluid" :class="!isForViewerPage() ? 'logoUser' : ''">
              </div>
              <div class="card-body">
                <h6 v-if="!isForViewerPage()" class="pl-2">L'url pour afficher tes dons en stream :</h6>

                <div class="input-group input-stream-width pl-2" v-if="!isForViewerPage()">
                  <div class="input-group-prepend">
                    <label class="input-group-text objet-clickable" @click.stop.prevent="refreshToken()" v-tooltip.left="'Régénérer le lien de stream'">
                      <i class="fas fa-sync"></i>
                    </label>
                  </div>
                  <input type="text" class="form-control not-modifiable spoiler" id="input-crypto-view" @click.stop.prevent="displayUrlStream()" v-bind:value="getUrl() +'/stream/'+ getCodeStream()" v-tooltip.top="'Afficher/cacher l\'url'" readonly>
                  <div class="btn-copy-address">
                    <a href="#" class="mr-4" @click.stop.prevent="copyAddress()" v-tooltip.top="'Copier le lien'">
                      <i class="fas fa-copy"></i>
                    </a>
                    <a href="#" @click.stop.prevent="goToGuideUrlStream()" v-tooltip.top="'Me montrer l\'explication'">
                      <img src="/img/question.svg" class="img-question">
                    </a>
                  </div>
                </div>

                <h6 v-if="!isForViewerPage()" class="pl-2">L'url de ta page de dons :</h6>

                <div class="input-group input-stream-width pl-2" v-if="!isForViewerPage()">
                  <div class="input-group-prepend">
                    <label class="input-group-text objet-clickable" @click.stop.prevent="downloadFile()" v-tooltip.left="'Télécharger l\'image'">
                      <i class="fas fa-download"></i>
                    </label>
                  </div>
                  <input type="text" class="form-control not-modifiable" v-bind:value="getUrlShareAccount()" readonly>
                  <div class="btn-copy-address">
                    <a href="#" class="mr-4" @click.stop.prevent="getUrlToViewer()" v-tooltip.top="'Copier le lien'">
                      <i class="fas fa-copy"></i>
                    </a>
                    <a href="#" @click.stop.prevent="goToGuideUrlAccount()" v-tooltip.top="'Me montrer l\'explication'">
                      <img src="/img/question.svg" class="img-question">
                    </a>
                  </div>
                </div>

                <ul class="nav nav-tabs nav-tabs-primary justify-content-center" id="ul-profil">
                  <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#link-address">
                      Mes adresses
                    </a>
                  </li>
                  <li class="nav-item" v-if="!isForViewerPage()">
                    <a class="nav-link" data-toggle="tab" href="#link-update">
                      Mon profil
                    </a>
                  </li>
                </ul>
                <div class="tab-content tab-subcategories">
                  <div class="tab-pane" id="link-address">
                    <div class="row">
                      <crypto-view :cryptoName="crypto" v-for="crypto in getListCryptos()" v-bind:key="crypto"></crypto-view>
                    </div>
                  </div>

                  <div class="tab-pane" id="link-update" v-if="!isForViewerPage()">

                    <form class="form pl-4" enctype="multipart/form-data">
                      <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <label class="input-group-text" for="logo-update-account" v-tooltip.left="'Logo'">
                            <i class="fas fa-camera"></i>
                          </label>
                        </div>
                        <div class="custom-file">
                          <input type="file" class="custom-file-input" id="logo-update-account" @change="uploadLogo" accept="image/*">
                          <label class="custom-file-label" for="logo-update-account">Modifier ton logo (de préférence un carré)</label>
                        </div>
                      </div>

                      <hr class="line-primary">

                      <div class="input-group">
                        <div class="input-group-prepend">
                          <label class="input-group-text" for="pseudo-update-account" v-tooltip.left="'Pseudo'">
                            <i class="fas fa-user"></i>
                          </label>
                        </div>
                        <input type="text" class="form-control" id="pseudo-update-account" v-model="formAccount.pseudo" placeholder="Pseudo" required>
                      </div>
                      <ul v-if="this.$store.getters.getErrors.updateAccount.pseudo.length != 0">
                        <li class="message-error" v-for="(errorText, index) in this.$store.getters.getErrors.updateAccount.pseudo" :key="index">
                          {{ errorText }}
                        </li>
                      </ul>

                      <div class="input-group">
                        <div class="input-group-prepend">
                          <label class="input-group-text" for="email-update-account" v-tooltip.left="'Email'">
                            <i class="fas fa-envelope"></i>
                          </label>
                        </div>
                        <input type="text" class="form-control" id="email-update-account" v-model="formAccount.email" placeholder="Email" required>
                      </div>
                      <ul v-if="this.$store.getters.getErrors.updateAccount.email.length != 0">
                        <li class="message-error" v-for="(errorText, index) in this.$store.getters.getErrors.updateAccount.email" :key="index">
                          {{ errorText }}
                        </li>
                      </ul>

                      <div class="input-group">
                        <div class="input-group-prepend">
                          <label class="input-group-text" for="link-twitter-update-account" v-tooltip.left="'Pseudo Twitter'">
                            <i class="fab fa-twitter"></i>
                          </label>
                        </div>
                        <input type="text" class="form-control" id="link-twitter-update-account" v-model="formAccount.pseudo_twitter" placeholder="Pseudo Twitter">
                      </div>
                      <ul v-if="this.$store.getters.getErrors.updateAccount.pseudo_twitter.length != 0">
                        <li class="message-error" v-for="(errorText, index) in this.$store.getters.getErrors.updateAccount.pseudo_twitter" :key="index">
                          {{ errorText }}
                        </li>
                      </ul>

                      <div class="input-group">
                        <div class="input-group-prepend">
                          <label class="input-group-text" for="link-twitch-update-account" v-tooltip.left="'Pseudo Twitch'">
                            <i class="fab fa-twitch"></i>
                          </label>
                        </div>
                        <input type="text" class="form-control" id="link-twitch-update-account" v-model="formAccount.pseudo_twitch" placeholder="Pseudo Twitch">
                      </div>
                      <ul v-if="this.$store.getters.getErrors.updateAccount.pseudo_twitch.length != 0">
                        <li class="message-error" v-for="(errorText, index) in this.$store.getters.getErrors.updateAccount.pseudo_twitch" :key="index">
                          {{ errorText }}
                        </li>
                      </ul>

                      <div class="input-group">
                        <div class="input-group-prepend">
                          <label class="input-group-text" for="link-facebook-update-account" v-tooltip.left="'Lien Facebook'">
                            <i class="fab fa-facebook"></i>
                          </label>
                        </div>
                        <input type="text" class="form-control" id="link-facebook-update-account" v-model="formAccount.link_facebook" placeholder="Lien Facebook">
                      </div>
                      <ul v-if="this.$store.getters.getErrors.updateAccount.link_facebook.length != 0">
                        <li class="message-error" v-for="(errorText, index) in this.$store.getters.getErrors.updateAccount.link_facebook" :key="index">
                          {{ errorText }}
                        </li>
                      </ul>

                      <div class="input-group">
                        <div class="input-group-prepend">
                          <label class="input-group-text" for="link-youtube-update-account" v-tooltip.left="'Lien Youtube'">
                            <i class="fab fa-youtube"></i>
                          </label>
                        </div>
                        <input type="text" class="form-control" id="link-youtube-update-account" v-model="formAccount.link_youtube" placeholder="Lien Youtube">
                      </div>
                      <ul v-if="this.$store.getters.getErrors.updateAccount.link_youtube.length != 0">
                        <li class="message-error" v-for="(errorText, index) in this.$store.getters.getErrors.updateAccount.link_youtube" :key="index">
                          {{ errorText }}
                        </li>
                      </ul>

                      <div class="input-group">
                        <div class="input-group-prepend">
                          <label class="input-group-text" for="link-tiktok-update-account" v-tooltip.left="'Lien Tiktok'">
                            <i class="fab fa-tiktok"></i>
                          </label>
                        </div>
                        <input type="text" class="form-control" id="link-tiktok-update-account" v-model="formAccount.link_tiktok" placeholder="Lien Tiktok">
                      </div>
                      <ul v-if="this.$store.getters.getErrors.updateAccount.link_tiktok.length != 0">
                        <li class="message-error" v-for="(errorText, index) in this.$store.getters.getErrors.updateAccount.link_tiktok" :key="index">
                          {{ errorText }}
                        </li>
                      </ul>

                      <div class="input-group">
                        <div class="input-group-prepend">
                          <label class="input-group-text" for="profil-text-update-account" v-tooltip.left="'Ta description'">
                            <i class="fas fa-quote-left"></i>
                          </label>
                        </div>
                        <textarea class="form-control" id="profil-text-update-account" v-model="formAccount.profil_text" placeholder="Ta description"></textarea>
                      </div>
                      <ul v-if="this.$store.getters.getErrors.updateAccount.profil_text.length != 0">
                        <li class="message-error" v-for="(errorText, index) in this.$store.getters.getErrors.updateAccount.profil_text" :key="index">
                          {{ errorText }}
                        </li>
                      </ul>

                      <button type="button" class="btn btn-info" data-toggle="modal" data-target="#modal-update-password">Changer ton mot de passe</button>
                      <button type="button" @click.stop.prevent="saveAccount()" class="btn btn-simple btn-primary btn-icon btn-round float-right" v-tooltip.bottom="'Enregistrer mes modifications'"><i class="fas fa-paper-plane"></i></button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>

        <div class="row row-grid align-items-center my-md" v-if="isForViewerPage()">
          <div class="col-lg-8">
            <h2 class="text-primary font-weight-light mb-2">Toi aussi tu es streamer ?</h2>
            <h4 class="mb-0 font-weight-light">Tu souhaites avoir ta propre page et tes alertes lorsqu'un don arrive ?!</h4>
          </div>
          <div class="col-lg-4 text-lg-center btn-wrapper">
            <button class="btn btn-primary btn-round btn-join-us" type="button" @click.stop.prevent="toRegisterPage()">
              <i class="fas fa-running"></i> Rejoins-nous !
            </button>
          </div>
        </div>
      </div>

      <hr class="line-primary hr-separation" v-if="!isForViewerPage()">

      <div class="container page-header align-items-center" v-if="!isForViewerPage()">
        <h3 class="mb-5 p-2">Le guide :</h3>
        <ul class="nav nav-pills nav-pills-primary nav-pills-icons p-2" id="nav-help">
          <li class="nav-item">
            <a class="nav-link active show" data-toggle="tab" href="#help-1">
              <i class="fas fa-atom"></i> Adresse
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#help-2">
              <i class="fas fa-link"></i> Lien
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#help-3">
              <i class="fas fa-cog"></i> Profil
            </a>
          </li>
        </ul>
        
        <div class="tab-content tab-space">
          <div class="tab-pane active" id="help-1">
            <div class="card-body">
              <span><button type="button" class="btn btn-simple btn-primary btn-icon btn-round"><i class="fas fa-plus"></i></button> permet d'ajouter de nouvelles crypto-monnaies sur ta page de dons !</span><br>
              <span>Il ouvre une page, et tu n'as plus qu'à cliquer sur la crypto-monnaie que tu souhaites ajouter :</span><br>
              <img src="/img/guide/help-crypto-add.png" class="shadow-lg" /><br>
              <span>Encoder l'adresse de la crypto-monnaie que tu as sélectionnée (Ici, j'ai sélectionné le Stellar Lumens, il me faut donc mon <b>adresse</b> Stellar Lumens et mon <b>memo</b> si mon wallet demande de l'indiquer) :</span><br>
              <img src="/img/guide/help-crypto-add-address.png" class="shadow-lg" /><br><br>
              <span>Après avoir ajouté les crypto-monnaies disponibles sur le site, tu auras ce message :</span><br>
              <img src="/img/guide/help-crypto-add-none.png" class="shadow-lg" /><br><br>
              <hr class="line-primary">
              <span><button type="button" class="btn btn-simple btn-primary btn-icon btn-round btn-update-address"><i class="fas fa-pencil-alt"></i></button> permet d'ouvrir le panel de modification de la crypto-monnaie de ta page de dons ci-dessus :</span><br>
              <img src="/img/guide/help-crypto-update-address.png" class="shadow-lg" /><br>
              <span><button type="button" class="btn btn-simple btn-primary btn-icon btn-round btn-delete-address"><i class="fas fa-times"></i></button> permet de supprimer la crypto-monnaie de ta page de dons !</span><br>
              <span>Modifies les champs <b>adresse</b> et <b>memo</b>, puis tu appuies sur le bouton <button type="button" class="btn btn-simple btn-primary btn-icon btn-round"><i class="fas fa-paper-plane"></i></button> pour valider le changement.</span>
            </div>
          </div>
          
          <div class="tab-pane" id="help-2">
            <div class="card-body">
              <div id="block-url-stream">
                <span>Afin d'afficher les dons en stream, il suffit d'ajouter une scène de type "<b>Navigateur</b>" et d'y mettre le lien ci-dessous :</span><br>
                <img src="/img/guide/help-link-stream.png" class="shadow-lg" /><br>
                <span>Le bouton <a href="#" class="p-2" @click.stop.prevent=""><i class="fas fa-paper-plane"></i></a> permet de copier le lien.</span><br><br>
                <span>Le bouton <a href="#" class="objet-clickable" @click.stop.prevent=""><i class="fas fa-sync"></i></a> permet de régénérer ton url de stream. (Si elle a fuité, par exemple 🤡)</span><br>
              </div>

              <hr class="line-primary">

              <div id="block-url-account">
                <span>Le lien suivant permet à tes viewers de venir sur ta page afin de réaliser un don (à mettre dans ta bio ou dans ton tchat) :</span>
                <img src="/img/guide/help-link-account.png" class="shadow-lg" /><br>
                <span>Le bouton <a href="#" class="p-2" @click.stop.prevent=""><i class="fas fa-paper-plane"></i></a> permet de copier le lien.</span><br><br>
                <span>Le bouton <a href="#" class="objet-clickable" @click.stop.prevent=""><i class="fas fa-download"></i></a> permet de télécharger une image à mettre dans ta bio (Si tu n'en as pas) :</span><br>
                <img src="/img/guide/don.png" class="shadow-lg" />
              </div>
            </div>
          </div>
          
          <div class="tab-pane" id="help-3">
            <div class="card-body">
              <span>Dans la partie profil, tu peux modifier toutes informations relatives à ton compte.</span><br>
              <span>Tu peux modifier ton logo en le sélectionnant (Si jamais ton logo ne s'affiche pas, n'hésites pas à rafraîchir la page)</span><br>
              
              <hr class="line-primary">
              
              <span>Pour ce qui est de la modification des informations du compte :</span><br>
              <span>Ton pseudo ne doit pas être utilisé sur notre site.</span><br>
              <span>Ton email ne doit pas être utilisé sur notre site.</span><br>
              <span>Ton pseudo <i class="fab fa-twitter"></i> est simplement le pseudo après le @ de ton compte.</span><br>
              <span>Ton pseudo <i class="fab fa-twitch"></i> est simplement ton pseudo.</span><br>
              <span>L'url de ta page <i class="fab fa-facebook"></i> (Encode une adresse complète https://facebook.com/...).</span><br>
              <span>L'url de ta page <i class="fab fa-youtube"></i> (Encode une adresse complète https://youtube.com/...).</span><br>

              <span><button type="button" class="btn btn-simple btn-primary btn-icon btn-round"><i class="fas fa-paper-plane"></i></button> enregistre les données que tu as demandé de modifier.</span><br>
              
              <hr class="line-primary">

              <span><button type="button" class="btn btn-info" data-toggle="modal" data-target="#modal-update-password">Changer ton mot de passe</button> ouvre une fenêtre qui permet de modifier ton mot de passe.</span>
            </div>
          </div>

        </div>  
      </div>  

    </div>
  </div>

  <!-- MODAL PASSWORD -->
  <div class="modal fade modal-black" id="modal-update-password" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header justify-content-center">
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
            <i class="fas fa-times text-white"></i>
          </button>
          <div class="text-muted text-center ml-auto mr-auto">
            <h3 class="mb-0">Changer ton mot de passe</h3>
          </div>
        </div>
        <div class="modal-body">
          <form role="form">

            <div class="form-group">
              <div class="input-group input-group-alternative input-password-width">
                <div class="input-group-prepend">
                  <label class="input-group-text" for="new-password" v-tooltip.left="'Nouveau mot de passe'">
                    <i class="fas fa-key"></i>
                  </label>
                </div>
                <input class="form-control" id="new-password" placeholder="Nouveau mot de passe" v-model="formPassword.password" v-on:keyup.enter="changePassword()" type="password">
                <div class="btn-show-password" v-tooltip.right="'Afficher/cacher le mot de passe'">
                    <a href="#" class="p-2 show-password" @click.stop.prevent="showChangePassword(0)">
                      <i class="fas fa-eye"></i>
                    </a>
                </div>
              </div>
            </div>
            <ul v-if="this.$store.getters.getErrors.changePassword.password.length != 0">
              <li class="message-error" v-for="(errorText, index) in this.$store.getters.getErrors.changePassword.password" :key="index">
                {{ errorText }}
              </li>
            </ul>

            <div class="form-group">
              <div class="input-group input-group-alternative input-password-width">
                <div class="input-group-prepend">
                  <label class="input-group-text" for="new-password-confirmation" v-tooltip.left="'Confirmation du nouveau mot de passe'">
                    <i class="fas fa-key"></i>
                  </label>
                </div>
                <input class="form-control" id="new-password-confirmation" placeholder="Confirmation du nouveau mot de passe" v-model="formPassword.password_confirm" v-on:keyup.enter="changePassword()" type="password">
                <div class="btn-show-password" v-tooltip.right="'Afficher/cacher le mot de passe'">
                    <a href="#" class="p-2 show-password" @click.stop.prevent="showChangePassword(1)">
                      <i class="fas fa-eye"></i>
                    </a>
                </div>
              </div>
            </div>
            <ul v-if="this.$store.getters.getErrors.changePassword.password_confirm.length != 0">
              <li class="message-error" v-for="(errorText, index) in this.$store.getters.getErrors.changePassword.password_confirm" :key="index">
                {{ errorText }}
              </li>
            </ul>
            
            <div class="text-center">
              <button type="button" @click.stop.prevent="changePassword()" class="btn btn-primary my-4">Let's go !</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- MODAL CRYPTO ADD -->
  <div v-if="!isForViewerPage()" class="modal fade modal-black" id="modal-add-address" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header justify-content-center">
          <router-link to="/faq?help=crypto" class="btn btn-icon btn-round btn-help-address" v-tooltip="'Comment avoir une adresse ?'" data-dismiss="modal">
            <i class="fas fa-question"></i>
          </router-link>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
            <i class="fas fa-times text-white"></i>
          </button>
          <div class="text-muted text-center ml-auto mr-auto">
            <h3 class="mb-0">Ajouter une adresse</h3>
          </div>
        </div>
        <div class="modal-body">
          <div class="alert alert-info text-center" v-if="!this.$store.getters.isUserHaveNotAllCrypto">Tu as ajouté toutes les crypto-monnaies disponibles pour le moment 👌</div>
          
          <div class="row" v-else>
            <div class="col-12 col-md-6 pb-4" v-for="crypto in getListCryptosAvailableToAdd()" v-bind:key="crypto">
              <crypto-add :cryptoName="crypto"></crypto-add>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import methods from '../methods.js';
import cryptoView from './crypto-view.vue';
import cryptoAdd from './crypto-add.vue';
import copy from 'copy-text-to-clipboard';
import { useStore } from 'vuex';
import { notify } from "@kyvg/vue3-notification";

export default{
  components: { cryptoView, cryptoAdd },
  created(){
    jQuery.fn.shake = function(interval,distance,times){
      interval = typeof interval == "undefined" ? 100 : interval;
      distance = typeof distance == "undefined" ? 10 : distance;
      times = typeof times == "undefined" ? 3 : times;
      var jTarget = $(this);
      jTarget.css('position','relative');
      for(var iter=0;iter<(times+1);iter++){
          jTarget.animate({ left: ((iter%2==0 ? distance : distance*-1))}, interval);
      }
      return jTarget.animate({ left: 0},interval);
    }
  },
  setup(){
    return {
      formAccount: {
        pseudo: useStore().getters.getUser == null ? "" : useStore().getters.getUser.pseudo,
        email: useStore().getters.getUser == null ? "" : useStore().getters.getUser.email,
        pseudo_twitter: useStore().getters.getUser == null ? "" : useStore().getters.getUser.pseudo_twitter,
        pseudo_twitch: useStore().getters.getUser == null ? "" : useStore().getters.getUser.pseudo_twitch,
        link_facebook: useStore().getters.getUser == null ? "" : useStore().getters.getUser.link_facebook,
        link_youtube: useStore().getters.getUser == null ? "" : useStore().getters.getUser.link_youtube,
        link_tiktok: useStore().getters.getUser == null ? "" : useStore().getters.getUser.link_tiktok,
        profil_text: useStore().getters.getUser == null ? "" : useStore().getters.getUser.profil_text,
      },
      formPassword: {
        password: "",
        password_confirm: ""
      }
    };
  },
  mounted(){
    if(this.$route.params.id == undefined){ // Compte par défaut 
      $("#ul-profil > li > a").removeClass("active");
      $(".tab-subcategories > .tab-pane").removeClass("active");

      $("#ul-profil > li > a[href='#link-update']").addClass("active");
      $("#link-update").addClass("active");
    }else{ // View du compte
      this.$store.dispatch('getViewAccount', { id: this.$route.params.id }).then(() => {
        // Si l'utilisateur existe, alors je mets à jour la visibilité sur les adresses du compte
        $("#ul-profil > li > a").removeClass("active");
        $(".tab-subcategories > .tab-pane").removeClass("active");

        $("#ul-profil > li > a[href='#link-address']").addClass("active");
        $("#link-address").addClass("active");
      }).catch((message_error) => {
				notify({
					text: "⛔ "+ message_error,
					type: "warn"
				});

        this.$router.push({name: 'Home'});
      });
    }
  },
  methods: {
    /** Account */
    isForViewerPage(){
      return this.$route.params.id != undefined;
    },
    displayPseudo(){
      return (this.$store.getters.getUser == null) ? "" : this.$store.getters.getUser.pseudo;
    },
    textIsExist(){
      return (this.$store.getters.getUser == null) ? false : this.$store.getters.getUser.profil_text != null;
    },
    displayProfilText(){
      return (this.$store.getters.getUser == null) ? "" : this.$store.getters.getUser.profil_text;
    },
    displayPseudoTwitter(){
      return (this.$store.getters.getUser == null) ? "" : this.$store.getters.getUser.pseudo_twitter;
    },
    displayPseudoTwitterIf(){
      return (this.$store.getters.getUser == null) ? false : this.$store.getters.getUser.pseudo_twitter != null;
    },
    displayPseudoTwitch(){
      return (this.$store.getters.getUser == null) ? "" : this.$store.getters.getUser.pseudo_twitch;
    },
    displayPseudoTwitchIf(){
      return (this.$store.getters.getUser == null) ? false : this.$store.getters.getUser.pseudo_twitch != null;
    },
    displayLinkFacebook(){
      return (this.$store.getters.getUser == null) ? "" : this.$store.getters.getUser.link_facebook;
    },
    displayLinkFacebookIf(){
      return (this.$store.getters.getUser == null) ? false : this.$store.getters.getUser.link_facebook != null;
    },
    displayLinkYoutube(){
      return (this.$store.getters.getUser == null) ? "" : this.$store.getters.getUser.link_youtube;
    },
    displayLinkYoutubeIf(){
      return (this.$store.getters.getUser == null) ? false : this.$store.getters.getUser.link_youtube != null;
    },
    displayLinkTiktok(){
      return (this.$store.getters.getUser == null) ? "" : this.$store.getters.getUser.link_tiktok;
    },
    displayLinkTiktokIf(){
      return (this.$store.getters.getUser == null) ? false : this.$store.getters.getUser.link_tiktok != null;
    },
    displayLogo(){
      return (this.$store.getters.getUser == null) ? "null.png" : this.$store.getters.getUser.logo;
    },
    getCodeStream(){
      return this.isForViewerPage() ? "" : (this.$store.getters.getUser == null) ? "" : this.$store.getters.getUser.code_stream;
    },
    getUserCryptoAddress(crypto){
      return this.isForViewerPage() ? false : this.$store.getters.getUserCrypto[crypto].address == null;
    },
    saveAccount(){
      this.$store.dispatch('saveAccount', this.formAccount).then(() => {
        notify({
          text: "🛠️ Tes informations ont bien été modifiées !",
          type: "success"
        });
      });
    },
    changePassword(){
      this.$store.dispatch('changePassword', this.formPassword).then(() => {
        $("#modal-update-password").modal("hide");

        this.formPassword.password = "";
        this.formPassword.password_confirm = "";

        $("#new-password").val("");
        $("#new-password-confirmation").val("");

        notify({
          text: "🔏 Ton mot de passe a bien été modifié !",
          type: "success"
        });
      });
    },
    showChangePassword(password_bool){
      let password = null;

      if(password_bool){
        password = $("#new-password-confirmation");
      }else{
        password = $("#new-password");
      }

      if(password.attr('type') === "password"){
        password.attr('type', 'text');
      }else{
        password.attr('type', 'password');
      }
    },
    async downloadFile(){
      const response = await axios.get("/img/guide/don.png", { responseType: "blob" });
      const blob = new Blob([response.data], { type: "png" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "don.png";
      link.click();
      URL.revokeObjectURL(link.href);
    },
    uploadLogo(e){
      let formData = new FormData();
      formData.append('logo', e.target.files[0]);

      this.$store.dispatch('updateLogo', formData).then(() => {
        notify({
          text: "🖼️ Ton logo a été modifié ! (Rafraichis ta page si tu ne vois pas de changement)",
          type: "success"
        });
      }).catch((message_error) => {
				notify({
					text: "⛔ "+ message_error,
					type: "warn"
				});
      });
    },
    clickChangeImg(){
      $("#logo-update-account").trigger("click");
    },

    /** Config */
    copyAddress(){
      copy(this.getUrl() +"/stream/"+ (this.$store.getters.getUser == null ? "" : this.$store.getters.getUser.code_stream));
      
      notify({
          text: "📝 L'url pour afficher tes dons en stream a été copié !",
          type: "success"
      });
    },
    getUrl(){
      return location.origin;
    },
    refreshToken(){
      this.$store.dispatch('refreshToken').then(() => {
        notify({
          text: "🔄 Ton lien de stream a été regénéré !",
          type: "success"
        });
      });
    },
    toRegisterPage(){
      this.$router.push({ name: 'Login'});
    },
    getUrlToViewer(){
      copy(this.getUrlShareAccount());
      
      notify({
          text: "📝 Ton lien a été copié !",
          type: "success"
      });
    },
    getUrlShareAccount(){
      return this.getUrl() +"/account/"+ (this.$store.getters.getUser == null ? "" : this.$store.getters.getUser.id);
    },
    displayUrlStream(){
      if($("#input-crypto-view").hasClass("spoiler")){
        $("#input-crypto-view").removeClass("spoiler");
      }else{
        $("#input-crypto-view").addClass("spoiler");
      }
    },
    removeActiveInNavLink(){
      $("ul#nav-help > li.nav-item > a.nav-link").removeClass("active");
      $("ul#nav-help > li.nav-item > a.nav-link").removeClass("show");
      $('div[id^="help-"]').removeClass("active"); // Bouger l'affichage du div qui est ouvert
    },
    goToGuideUrlStream(){
      this.removeActiveInNavLink();

      $('a[href="#help-2"]').addClass("active"); // Affiche le pills de la bonne couleur
      $('#help-2').addClass("active"); // Affichage du div explicatif

      // Scroll
      $('html, body').animate({
        scrollTop: $('a[href="#help-2"]').offset().top
      }, 1000, function() {
        // Montrer l'explication à l'utilisateur plus explicitement
        $("#block-url-stream").shake(100, 3, 1);
      });
    },
    goToGuideUrlAccount(){
      this.removeActiveInNavLink();

      $('a[href="#help-2"]').addClass("active"); // Affiche le pills de la bonne couleur
      $('#help-2').addClass("active"); // Affichage du div explicatif

      // Scroll
      $('html, body').animate({
        scrollTop: $('a[href="#help-2"]').offset().top
      }, 1000, function() {
        // Montrer l'explication à l'utilisateur plus explicitement
        $("#block-url-account").shake(100, 3, 1);
      });
    },
    getNbrOfAddressAvailable(){
      let cptNull = 0;

      if(this.$store.getters.getUserCrypto == null){
        return "";
      }

      for (const [key, value] of Object.entries(this.$store.getters.getUserCrypto)) {
        if(value.address == null){
          cptNull++;
        }
      }

      return cptNull == 0 ? "" : cptNull;
    },
    getListCryptosAvailableToAdd(){
      return methods.getAllCryptos().filter(crypto => this.getUserCryptoAddress(crypto));
    },
    getListCryptos(){
      return methods.getAllCryptos();
    },
  }
}
</script>

<style scoped>
  .main{
    overflow-y: hidden;
  }
  .path-in{
    margin-right: 50px;
  }
  .espace-div-top{
    margin-top: 180px;
  }
  .profile-title{
    margin-top: 150px;
  }
  .btn-add-address{
    position: absolute;
    top: 10px;
    right: 25px;
    z-index: 50;
  }
  @media (max-width: 576px) {
    .btn-add-address{
      top: 90px;
    }
  }
  @media (max-width: 992px) {
    .espace-div-top{
      margin-top: 0px;
    }
  }
  .btn-show-password{
    position:absolute; 
    right: -36px; 
    top: 8px;
  }
  .input-password-width{
    width: 92%;
  }
  .card-coin{
    min-height: 100px;
  }
  .btn-copy-address{
    position:absolute; 
    right: -60px; 
    top: 8px;
  }
  .input-stream-width{
    width: 92%;
  }
  .objet-clickable{
    cursor: pointer;
  }
  .spoiler {
    filter: blur(4px);
    transition-property: -webkit-filter;
    transition-duration: .5s;
  }
  .not-modifiable{
    user-select: none;
  }
  input[readonly]{
    background-color: transparent !important;
  }
  .custom-file-label{
    border-color: #2b3553;
    border-left: none;
    padding-left: 18px;
    padding-top: 10px;
  }
  .custom-file-label::after{
    content: none;
    border-left: none;
  }
  .img-question{
    width: 10px !important;
  }
  .badge-nbr-add-address{
    position: absolute;
    right: -8px;
    top: -6px;
  }
  .logoUser:hover{
    opacity: 0.8;
    cursor: pointer;
  }
  .hr-separation{
    width: 50%; 
    margin-left: 25%;
  }
  .btn-join-us{
    padding-top: 18px;
    padding-bottom: 18px;
  }
</style>