<template>
    <div class="card card-add-address" v-bind:id="'add-token-'+ cryptoName">
        <div class="card-inside-vue text-center" @click.stop.prevent="turnCardAddToken()">
            <h2><img v-bind:src="'/img/crypto/'+ cryptoName +'.png'" width="80">{{ this.$store.getters.getUserCrypto[cryptoName].name }}</h2>
        </div>
        <div class="card-inside-input text-center">
            <div class="input-group p-1 div-memo">
                <div class="input-group-prepend">
                    <label class="input-group-text" v-bind:for="'input-token-'+ cryptoName" v-tooltip.left="'Adresse'">
                        <i class="fas fa-file-alt"></i>
                    </label>
                </div>
                <input type="text" class="form-control" v-bind:id="'input-token-'+ cryptoName" v-bind:placeholder="'Adresse '+ this.$store.getters.getUserCrypto[cryptoName].name" required>
            </div>
            <div class="input-group p-1">
                <div class="input-group-prepend">
                    <label class="input-group-text" v-bind:for="'input-token-memo-'+ cryptoName" v-tooltip.left="'Memo'">
                        <i class="fas fa-tag"></i>
                    </label>
                </div>
                <input type="text" class="form-control" v-bind:id="'input-token-memo-'+ cryptoName" v-bind:placeholder="trueIfMemoExist(cryptoName) ? 'Memo' : 'Pas besoin de memo'" :disabled="!trueIfMemoExist(cryptoName)">
            </div>
            <p class="message-error" v-bind:id="'message-add-error-'+ cryptoName"></p>

            <button class="btn btn-secondary btn-dissmiss-add-address" @click.stop.prevent="turnCardAddToken()" v-tooltip.bottom="'Annuler l\'ajout'"><i class="fas fa-times"></i></button>
            <button class="btn btn-primary btn-send-new-address" v-bind:id="'btn-token-add-'+ cryptoName" @click.stop.prevent="addToken()" v-tooltip.bottom="'Ajouter l\'adresse sur mon profil'"><i class="fas fa-paper-plane"></i></button>
        </div>
    </div>
</template>

<script>
import methods from '../methods';
import { notify } from "@kyvg/vue3-notification";

export default({
    created(){
      this.trueIfMemoExist = methods.trueIfMemoExist;
    },
    props: ['cryptoName'],
    methods: {
      turnCardAddToken(){
          $("#input-token-"+ this.cryptoName).removeClass("is-invalid");

          if($("#add-token-"+ this.cryptoName).hasClass("return-carte")){
              $("#add-token-"+ this.cryptoName).removeClass("return-carte");
          }else{
              $("#add-token-"+ this.cryptoName).addClass("return-carte");
          }
      },
      async addToken(){
          let address = $("#input-token-"+ this.cryptoName).val().trim();
          let memo = $("#input-token-memo-"+ this.cryptoName).val().trim();

          $("#message-add-error-"+ this.cryptoName).text("");
          $("#btn-token-add-"+ this.cryptoName).addClass("disabled");

          // Reset error
          $("#input-token-"+ this.cryptoName).removeClass("is-invalid");

          // Verify error
          methods.testCryptoAddress(this.cryptoName, address).then(() => {
            this.$store.dispatch('addAddress', {
              name: this.cryptoName,
              address: address,
              memo: memo
            }).then(() => {
              notify({
                  text: "🗳️ Votre adresse a bien été ajoutée !",
                  type: "success"
              });
            });
          }).catch(() => {
            $("#input-token-"+ this.cryptoName).addClass("is-invalid");

            $("#message-add-error-"+ this.cryptoName).text("Votre adresse n'existe pas.");
            
            $("#btn-token-add-"+ this.cryptoName).removeClass("disabled");
          });
      }
    }
});
</script>

<style scoped>
  input[disabled]{
    background-color: transparent !important;
  }
  .card-add-address{
    width: 100%;
    height: 100px;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
  .card-inside-vue, .card-inside-input {
    position: absolute;
    width: 100%;
    min-height: 130px;
    background-color: #1F2251;
    backface-visibility: hidden;
    box-shadow: 3px 3px 10px black !important;
  }
  .card-inside-input{
    transform: rotateY(180deg);
  }
  .card-inside-vue{
    cursor: pointer;
    line-height: 130px;
  }
  .card-inside-vue > h2{
    display:inline-block;
    vertical-align: middle;
    line-height: normal;
  }
  .div-memo{
    margin-bottom: 0 !important;
  }
  .card-inside-vue > h2{
    font-size: 18px; 
    margin-bottom: 0;
  }
  .return-carte{
    transform: rotateY(180deg);
  }
  .btn-dissmiss-add-address{
    position: absolute; 
    left:5px; 
    bottom: 0;
    padding: 6px 12px;
  }
  .btn-send-new-address{
    position: absolute; 
    right:5px; 
    bottom: 0;
    padding: 6px 12px;
  }
  .message-error{
    color: tomato !important;
    font-size: 80%;
    text-align: center;
  }
</style>