import axios from 'axios';

export default {
    getAllCryptos: function () {
        // TODO: Cryptos ADD
        return [
            "xlm",
            "algo",
            "flux",
            //"atom",
        ];
    },
    testCryptoAddress: function(cryptoName, address) {
        switch(cryptoName){
            // TODO: Cryptos ADD
            case "xlm":
                return new Promise((resolve, reject) => {
                    axios.get('https://horizon.stellar.org/accounts/'+ address).then(() => {
                        resolve();
                    }).catch((err) => {
                        reject();
                    });
                });
            case "algo": 
                return new Promise((resolve, reject) => {
                    axios.get('https://algoindexer.algoexplorerapi.io/v2/accounts/'+ address).then(() => {
                        resolve();
                    }).catch((err) => {
                        reject();
                    });
                });
            case "flux": 
                return new Promise((resolve, reject) => {
                    axios.get('https://api.runonflux.io/explorer/utxo?address='+ address).then((response) => {
                        if(response.data.status == "error"){
                            reject();
                        }else{
                            resolve();
                        }
                    }).catch((err) => {
                        reject();
                    });
                });
            case "atom": 
                return new Promise((resolve, reject) => {
                    axios.get('https://api.cosmos.network/bank/balances/'+ address).then(() => {
                        resolve();
                    }).catch((err) => {
                        reject();
                    });
                });
        }

        return new Promise((resolve, reject) => {
            reject();
        });
    },
    trueIfMemoExist: function(cryptoName){
        // TODO: Cryptos ADD
        switch(cryptoName){
            case "xlm":
                return true;
        }
        
        return false;
    },
    setUserAccount(state, object){
        // TODO: Cryptos ADD
        state.account.user = object.user;
        state.account.cryptos.algo.address = object.cryptos.algo;
        state.account.cryptos.xlm.address = object.cryptos.xlm;
        state.account.cryptos.xlm.memo = object.cryptos.xlm_memo;
        state.account.cryptos.flux.address = object.cryptos.flux;
        state.account.cryptos.atom.address = object.cryptos.atom;
    },
};