<template>
  <div class="wrapper index-page">
    <section class="page-header header-filter">
      <div class="squares square1"></div>
      <div class="squares square2"></div>
      <div class="squares square3"></div>
      <div class="squares square4"></div>
      <div class="squares square5"></div>
      <div class="squares square6"></div>
      <div class="squares square7"></div>
      <div class="container">
        <div class="content-center brand">
          <h1 class="h1-seo">Crypto-Don</h1>
          <h3>Le nouveau moyen d'avoir des dons en crypto-monnaie, directement visible depuis votre stream !</h3>
          <p class="text-center">
            <button class="btn btn-primary btn-round" type="button" @click.stop.prevent="toRegisterPage()">
              <i class="fas fa-running"></i> Rejoins-nous !
            </button>
          </p>
        </div>
        <div style="position:absolute; bottom: 10px; margin-left: 47%">
          <button class="btn btn-primary btn-icon btn-round" type="button" id="btn-next">
            <i class="fas fa-angle-down"></i>
          </button>
        </div>
      </div>
    </section>

    <div class="main landing-page" id="next-of-page">
      <section class="section section-lg section-crypto">
        <div class="squares square-1"></div>
        <div class="squares square-2"></div>
        <div class="squares square-3"></div>
        <div class="squares square-4"></div>
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-lg-12">
              <h1 class="text-center">3 points importants :</h1>
              <div class="row row-grid justify-content-center">
                <div class="col-lg-3">
                  <div class="info text-center">
                    <div class="icon icon-primary">
                      <i class="fas fa-money-bill-wave"></i>
                    </div>
                    <h4 class="info-title">Gratuit</h4>
                    <hr class="line-primary justify-content-center">
                    <p>La base du site est complètement gratuite, tu peux t'inscrire pour ajouter tes adresses crypto-monnaies disponibles sur le site.</p>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="info text-center">
                    <div class="icon icon-warning">
                      <i class="fas fa-cubes"></i>
                    </div>
                    <h4 class="info-title">Simple</h4>
                    <hr class="line-warning">
                    <p>En plus d'avoir un guide en dessous de ta page de compte, tu as une description à chaque fois que tu mets ton curseur sur un bouton, ...</p>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="info text-center">
                    <div class="icon icon-success">
                      <i class="fas fa-puzzle-piece"></i>
                    </div>
                    <h4 class="info-title">Mise à jour</h4>
                    <hr class="line-success">
                    <p>Le site sera régulièrement mis à jour afin d'ajouter de nouvelles crypto-monnaies, mais aussi d'améliorer le site grâce à vos idées.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    
    <div class="main landing-page" id="next-of-page">
      <section class="section section-lg">
        <img src="/img/path5.png" class="path path-dispo">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <h1 class="text-center">Les crypto-monnaies disponibles :</h1>
              <div class="row justify-content-center">
                <div class="col-lg-4 text-center" v-for="crypto in getListCryptos()" v-bind:key="crypto">
                  <div class="card">
                    <div class="card-body text-center card-crypto-info">
                      <img :src="'/img/crypto/'+ crypto +'.png'" :alt="crypto">
                      <h4 class="pt-4">{{ getCryptoName(crypto) }}</h4>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 text-center">
                  <div class="card">
                    <div class="card-body text-center card-crypto-info">
                      <h4 class="pt-4 card-crypto-other">D'autres crypto-monnaies à venir ...</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    
    <div class="main landing-page">
      <section class="section section-lg section-stats">
        <img src="/img/path1.png" class="path">
        <img src="/img/path4.png" class="path2">
        <div class="container">
          <div class="row row-grid justify-content-between">
            <div class="col-md-5 mt-lg-5">
              <div class="row">
                <div class="col-lg-6 col-sm-12 px-2 py-2">
                  <div class="card card-stats ">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-5 col-md-4">
                          <div class="icon-big text-center icon-warning">
                            <i class="fas fa-link text-warning"></i>
                          </div>
                        </div>
                        <div class="col-7 col-md-8">
                          <div class="numbers">
                            <p class="card-title">
                              <vue3-autocounter ref='counter' :startAmount='0' :endAmount='$store.getters.getStatsGlobal.addresses' :duration='1' :autoinit='true' />
                            </p>
                            <p class="card-category">Adresses</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-12 px-2 py-2">
                  <div class="card card-stats upper bg-default">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-5 col-md-4">
                          <div class="icon-big text-center icon-warning">
                            <i class="fas fa-coins text-white"></i>
                          </div>
                        </div>
                        <div class="col-7 col-md-8">
                          <div class="numbers">
                            <p class="card-title">
                              <vue3-autocounter ref='counter' :startAmount='0' :endAmount='$store.getters.getStatsGlobal.donations' :duration='1' :autoinit='true' />
                            </p>
                            <p class="card-category">Dons</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6 col-sm-12 px-2 py-2">
                  <div class="card card-stats ">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-5 col-md-4">
                          <div class="icon-big text-center icon-warning">
                            <i class="fas fa-users text-info"></i>
                          </div>
                        </div>
                        <div class="col-7 col-md-8">
                          <div class="numbers">
                            <p class="card-title">
                              <vue3-autocounter ref='counter' :startAmount='0' :endAmount='$store.getters.getStatsGlobal.members' :duration='1' :autoinit='true' />
                            </p>
                            <p class="card-category">Membres</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-12 px-2 py-2">
                  <div class="card card-stats ">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-5 col-md-4">
                          <div class="icon-big text-center icon-warning">
                            <i class="fas fa-bezier-curve text-success"></i>
                          </div>
                        </div>
                        <div class="col-7 col-md-8">
                          <div class="numbers">
                            <p class="card-title">
                              <vue3-autocounter ref='counter' :startAmount='0' :endAmount='$store.getters.getStatsGlobal.sharedNetworks' :duration='1' :autoinit='true' />
                            </p>
                            <p class="card-category">Réseaux partagés</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="pl-md-5 pt-5">
                <h1>Nos
                  <br/>Statistiques</h1>
                <p>Nous sommes totalement transparents avec vous !</p>
                <br/>
                <p>Voici quelques statistiques concernant la plateforme.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import methods from '../methods.js';

export default{
  mounted(){
    this.$store.dispatch('getStatsGlobal');

    $("#btn-next").click(function() {
      $('html, body').animate({
          scrollTop: $("#next-of-page").offset().top
      }, 1000);
    });
  },
  methods: {
    toRegisterPage(){
      this.$router.push({ name: 'Login'});
    },
    getListCryptos(){
      return methods.getAllCryptos();
    },
    getCryptoName(crypto){
      return this.$store.getters.getUserCrypto[crypto].name;
    },
  }
}
</script>
<style scoped>
.info > hr{
  margin-left: auto;
}
@media (max-width: 992px) {
  .page-header > .square2{
    display: none;
  }
  .page-header > .square7{
    display: none;
  }
  .section-crypto > .square-1{
    top: 600px;
  }
  .section-crypto > .square-2{
    left: 40px;
  }
  .section-crypto > .square-3{
    top: 800px;
  }
  .section-stats{
    padding: 10px 0;
  }
}
</style>