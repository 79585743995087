<template>
  <div class="wrapper faq-page">
    <div class="page-header espace-min-height">
      <div class="content">
        <img src="/img/path1.png" class="path">
        <img src="/img/path2.png" class="path2">

        <img src="/img/password.svg" width="420" />

        <div class="container">
          <div class="card">
            <div class="card-header">
              <h2>Réinitialiser ton mot de passe !</h2>
            </div>
            <div class="card-body">
              
              <form class="form">
                <div class="form-group">
                  <div class="form-input text-left">
                    <label for="input-email">Ton email</label>
                    <input type="text" class="form-control" placeholder="Ton email" id="input-email" v-model="formResetPassword.email">
                  </div>
                  <ul v-if="this.$store.getters.getErrors.resetPassword.email.length != 0">
                    <li class="message-error" v-for="(errorText, index) in this.$store.getters.getErrors.resetPassword.email" :key="index">
                      {{ errorText }}
                    </li>
                  </ul>
                </div>
                
                <div class="form-group pt-2">
                  <div class="form-input text-left">
                    <label for="input-code">Le code reçu par mail</label>
                    <input type="text" class="form-control" placeholder="Ton code" v-model="formResetPassword.code" id="input-code">
                  </div>
                </div>
                
                <div class="form-group input-password-width pt-2">
                  <label for="input-code">Ton nouveau mot de passe</label>
                  <input type="password" class="form-control" id="input-password" v-model="formResetPassword.password" placeholder="Mot de passe" v-on:keyup.enter="submit()" required>
                  <div class="btn-show-password" v-tooltip.right="'Afficher/cacher le mot de passe'">
                      <a href="#" class="p-2 show-password" @click.stop.prevent="showPasswordRegister()">
                        <i class="fas fa-eye"></i>
                      </a>
                  </div>
                </div>
                <ul v-if="this.$store.getters.getErrors.resetPassword.password.length != 0">
                  <li class="message-error" v-for="(errorText, index) in this.$store.getters.getErrors.resetPassword.password" :key="index">
                    {{ errorText }}
                  </li>
                </ul>

                <div class="container text-center pt-2">
                  <button type="submit" id="submit-contact" class="btn btn-outline-success" @click.stop.prevent="submit()">Envoyer !</button>
                </div>
              </form>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { notify } from "@kyvg/vue3-notification";

export default{
  created () {
    if(this.$route.params.code != null){
      this.formResetPassword.code = this.$route.params.code;
      $("#input-code").val(this.formResetPassword.code);
    }

    if(this.$route.query.email != null){
      this.formResetPassword.email = this.$route.query.email;
      $("#input-email").val(this.formResetPassword.email);
    }
  },
  setup(){
    const formResetPassword = {
      email: "",
      code: "",
      password: "",
    };

    return {
      formResetPassword,
    }
  },
  methods: {
    showPasswordRegister(){
      let password = $("#input-password");

      if(password.attr('type') === "password"){
        password.attr('type', 'text');
      }else{
        password.attr('type', 'password');
      }
    },
    submit(){
      this.$store.dispatch('resetPassword', this.formResetPassword).then(() => {
        notify({
          text: "🔑 Ton mot de passe a bien été réinitialisé !",
          type: "success"
				});

        this.$router.push({ name: "Login" });
      }).catch((err) => {
        notify({
          text: "🔑 "+ err,
          type: "error"
        });
      });
    },
  },
}
</script>
<style scoped>
.btn-show-password{
  position: absolute; 
  right: -40px; 
  top: 42px;
}
.input-password-width{
  width: 96%;
  text-align: left;
}
</style>